import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Form, Input } from "antd";
import { uniqueId } from "lodash-es";
import { useMutation } from "@apollo/client";
import { use, useSmart } from "@bluelibs/x-ui";
import { AssetService } from "../../services/Asset.service";
import { IconTrash, IconArrowLeft } from "../svg/icons";
import { UPDATE_ASSET_INFO, REMOVE_PRODUCT_FROM_SPOT } from "../../mutations";
import { Button, ButtonLoader } from "../button";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import { toast } from "react-toastify";
const ProjectPositionForm = ({ form, projectId, onBack }) => {
    const t = useTranslate("components.projectPosition");
    const assetService = use(AssetService);
    const projectSmart = useSmart(ProjectSmart);
    const { state } = projectSmart;
    const [updateAssetInfo, { loading }] = useMutation(UPDATE_ASSET_INFO);
    const [removeProductFromSpot] = useMutation(REMOVE_PRODUCT_FROM_SPOT);
    const currentlySelectedAsset = state.project.assetInfos.find((asset) => asset._id === state.currentlySelectedAssetId);
    // TODO: use only e.target.[id, value] and update just that
    const changeDeltaPositions = async (e) => {
        const formPositions = form.getFieldsValue();
        const deltaPositions = assetService.spatialPositionFormToSpatialPosition(formPositions);
        const newPositions = assetService.calculatePositionWithDelta(currentlySelectedAsset.assetPosition.coordinates, deltaPositions);
        const asset = assetService.findWorldAssetEntityById(currentlySelectedAsset._id);
        assetService.updatePositionsForAsset(asset, newPositions);
    };
    const updateDeltaPositions = async () => {
        try {
            const formPositions = form.getFieldsValue();
            const deltaCoordinates = assetService.spatialPositionFormToSpatialPosition(formPositions);
            await updateAssetInfo({
                variables: {
                    input: {
                        assetInfoId: currentlySelectedAsset._id,
                        deltaCoordinates,
                    },
                },
            });
            const assetInfos = [...state.project.assetInfos];
            const assetInfoIndex = assetInfos.findIndex((item) => item._id === currentlySelectedAsset._id);
            const oldAsset = { ...assetInfos[assetInfoIndex] };
            oldAsset.deltaCoordinates = deltaCoordinates;
            assetInfos.splice(assetInfoIndex, 1, oldAsset);
            projectSmart.updateState({
                project: {
                    ...state.project,
                    assetInfos,
                },
            });
            projectSmart.updateState({
                currentlySelectedAssetId: oldAsset._id,
            });
            toast.info(t("updateDeltaPositions"));
        }
        catch (err) {
            toast.error(err.toString());
        }
    };
    const onRemoveAsset = async () => {
        try {
            const assetId = currentlySelectedAsset._id;
            await removeProductFromSpot({
                variables: {
                    input: {
                        projectId,
                        projectAssetInfoId: assetId,
                    },
                },
            });
            projectSmart.updateState({ currentlySelectedAssetId: null });
            const assetInfos = [...state.project.assetInfos];
            const assetInfoIndex = assetInfos.findIndex((item) => item._id === assetId);
            const oldAsset = { ...assetInfos[assetInfoIndex] };
            oldAsset.product = undefined;
            assetInfos.splice(assetInfoIndex, 1, oldAsset);
            projectSmart.updateState({
                project: {
                    ...state.project,
                    assetInfos,
                },
            });
            toast.info(t("removeProduct"));
        }
        catch (err) {
            toast.error(err.toString());
        }
    };
    return (_jsxs("div", Object.assign({ className: "cc-position-wrapper" }, { children: [_jsxs("div", Object.assign({ className: "cc-position-header" }, { children: [_jsx("button", Object.assign({ className: "cc-button cc--outlined cc--primary cc--bg-primary cc-position-back__btn", onClick: onBack }, { children: _jsx("span", { children: _jsx(IconArrowLeft, {}, void 0) }, void 0) }), void 0), _jsx("div", Object.assign({ className: "cc-position-wrapper__heading" }, { children: t("editPosition") }), void 0)] }), void 0), _jsxs(Form, Object.assign({ onFinish: updateDeltaPositions, initialValues: assetService.spatialPositionToSpatialPositionForm(currentlySelectedAsset.deltaCoordinates), onChange: changeDeltaPositions, form: form }, { children: [["position", "rotation", "scale"].map((type) => {
                        return (_jsxs(Form.Item, Object.assign({ className: "cc-position-form__row" }, { children: [_jsx("div", Object.assign({ className: "cc-position-label" }, { children: type }), void 0), _jsx("div", Object.assign({ className: "cc-position-form__group" }, { children: ["x", "y", "z"].map((direction) => {
                                        return (_jsx(Form.Item, Object.assign({ name: [type, direction], className: "cc-position-input__wrapper", label: direction }, { children: _jsx(Input, { type: "number", className: "cc-position-input" }, void 0) }), uniqueId("form-item-2-")));
                                    }) }), void 0)] }), uniqueId("form-item-1-")));
                    }), _jsxs("div", Object.assign({ className: "cc-position-form__action-group" }, { children: [_jsx("div", Object.assign({ className: "cc-position-form__action-item" }, { children: _jsx(Button, Object.assign({ variant: "outlined", bgColor: "primary", color: "primary", size: "small", onClick: onRemoveAsset }, { children: _jsx(IconTrash, {}, void 0) }), void 0) }), void 0), _jsx("div", Object.assign({ className: "cc-position-form__action-item" }, { children: _jsx(ButtonLoader, Object.assign({ size: "custom", type: "submit", className: "cc-position-form__action-update", isLoading: loading }, { children: t("update") }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default ProjectPositionForm;
