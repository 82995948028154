import { jsx as _jsx } from "react/jsx-runtime";
import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { useAppGuardian } from "@bundles/UIAppBundle/services";
import { useEffect } from "react";
const EmailVerifiedPage = ({ token }) => {
    const router = useRouter();
    const guardian = useAppGuardian();
    useEffect(() => {
        if (!token) {
            router.go(Routes.HOME);
            return;
        }
        guardian.reissueToken(token).finally(() => {
            router.go(Routes.HOME);
        });
    }, []);
    return _jsx(Loader, { centerInScreen: true }, void 0);
};
export default EmailVerifiedPage;
