import { gql } from "@apollo/client";
export const ADD_PRODUCT_TO_WISHLIST = gql `
  mutation ($input: EndUsersWishlistsAddInput!) {
    EndUsersWishlistsAdd(input: $input)
  }
`;
export const REMOVE_PRODUCT_FROM_WISHLIST = gql `
  mutation ($input: EndUsersWishlistsRemoveInput!) {
    EndUsersWishlistsRemove(input: $input)
  }
`;
export const CHANGE_QUANTITY_WISHLIST_ITEM = gql `
  mutation ($input: EndUsersWishlistsChangeQuantityInput!) {
    EndUsersWishlistsChangeQuantity(input: $input)
  }
`;
