import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { Button } from "@bundles/UIAppBundle/components/button";
const CheckoutActionCard = ({ text, buttonText, onClick, theme = "dark", isButtonDisabled, }) => {
    const cardClasses = classNames("cc-checkout-action__card", `cc--${theme}`);
    let buttonProps = {
        variant: "filled",
        bgColor: "white",
        color: "gray",
    };
    if (theme === "light") {
        buttonProps = {
            variant: "outlined",
            bgColor: "gray",
            color: "gray",
        };
    }
    return (_jsxs("div", Object.assign({ className: cardClasses }, { children: [_jsx("div", Object.assign({ className: "cc-checkout-action__text" }, { children: text }), void 0), _jsx("div", { children: _jsx(Button, Object.assign({ disabled: isButtonDisabled }, buttonProps, { weight: "bold", onClick: onClick }, { children: buttonText }), void 0) }, void 0)] }), void 0));
};
export default CheckoutActionCard;
