import { jsx as _jsx } from "react/jsx-runtime";
import { newSmart } from "@bluelibs/smart";
import { ProjectSmart, } from "@bundles/UIAppBundle/smarts/Project.smart";
import { useEffect, useState } from "react";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { NotFound } from "@bundles/UIAppBundle/overrides/NotFound";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { ShopSmart } from "@bundles/UIAppBundle/smarts/Shop.smart";
import { AcceptedLanguage, } from "@root/api.types";
import { useLazyQuery, useQuery } from "@apollo/client";
import { SHOP_GET_PRODUCT_CATEGORIES } from "@bundles/UIAppBundle/queries/shopGetProductCategories.query";
import { ShopSidebarProvider, useShopSidebarContext } from "./sidebar";
import { ShopProductsComponent } from "./shopProducts";
import { SHOP_GET_TAGS } from "@bundles/UIAppBundle/queries/shopsGetTags.query";
import { GET_SHOP_INFO } from "@bundles/UIAppBundle/queries/getShopInfo.query";
import { SHOP_GET_PRODUCTS } from "@bundles/UIAppBundle/queries/shopGetProducts";
import { ShopLayout } from "@bundles/UIAppBundle/components/shop-layout";
export const ShopPageWrapper = ({ linkName }) => {
    const [_, ShopSmartProvider] = newSmart(ShopSmart);
    const [_2, BasketSmartProvider] = newSmart(BasketSmart);
    const [_3, ProjectSmartProvider] = newSmart(ProjectSmart);
    return (_jsx(ProjectSmartProvider, { children: _jsx(ShopSidebarProvider, { children: _jsx(BasketSmartProvider, { children: _jsx(ShopSmartProvider, { children: _jsx(ShopPage, { linkName: linkName }, void 0) }, void 0) }, void 0) }, void 0) }, void 0));
};
export const ShopPage = ({ linkName }) => {
    const [shopProducts, setShopProducts] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [shopInfo, setShopInfo] = useState(null);
    const [limit, setLimit] = useState(50);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [productCategories, setProductCategories] = useState(null);
    const [tags, setTags] = useState(null);
    const { filters } = useShopSidebarContext();
    const uiSession = useUISession();
    useEffect(() => {
        uiSession.set("lastShopLinkName", linkName, {
            persist: true,
        });
    }, []);
    const {} = useQuery(GET_SHOP_INFO(uiSession.state.currentLocale || AcceptedLanguage.en), {
        variables: {
            input: {
                linkName,
            },
        },
        onCompleted: (data) => {
            setShopInfo(data.EndUsersGetShopInfo);
        },
        onError: (err) => {
            setHasError(true);
        },
    });
    const {} = useQuery(SHOP_GET_TAGS, {
        variables: {
            input: {
                linkName,
            },
        },
        onCompleted: (data) => {
            setTags(data.EndUsersShopGetTags);
        },
    });
    const { loading: loadingProductCategories } = useQuery(SHOP_GET_PRODUCT_CATEGORIES, {
        variables: {
            input: {
                linkName,
            },
        },
        onCompleted: (data) => {
            setProductCategories(data.EndUsersShopGetProductCategories);
        },
    });
    const [getProducts, { loading: loadingProducts }] = useLazyQuery(SHOP_GET_PRODUCTS(uiSession.state.currentLocale || AcceptedLanguage.en), {
        fetchPolicy: "network-only",
    });
    const fetchMoreProducts = async () => {
        setIsFetchingMore(true);
        await getProducts({
            variables: {
                input: {
                    skip,
                    filters: {
                        category: filters === null || filters === void 0 ? void 0 : filters.category,
                        tags: filters === null || filters === void 0 ? void 0 : filters.tags,
                        text: filters === null || filters === void 0 ? void 0 : filters.text,
                    },
                    limit,
                    linkName,
                },
            },
            onCompleted: (data) => {
                const prods = data.EndUsersGetShopProducts;
                setShopProducts((prev) => (prev || []).concat(prods));
                setSkip((prev) => prev + limit);
                setHasMore(prods.length === limit);
            },
        });
        setIsFetchingMore(false);
    };
    const fetchProductsOnFiltersChange = async () => {
        setSkip(0);
        setHasMore(true);
        await getProducts({
            variables: {
                input: {
                    skip: 0,
                    limit,
                    filters: {
                        category: filters === null || filters === void 0 ? void 0 : filters.category,
                        tags: filters === null || filters === void 0 ? void 0 : filters.tags,
                        text: filters === null || filters === void 0 ? void 0 : filters.text,
                    },
                    linkName,
                },
            },
            onCompleted: (data) => {
                const prods = data.EndUsersGetShopProducts;
                setShopProducts(prods);
                setSkip(limit);
                setHasMore(prods.length === limit);
            },
        });
    };
    useEffect(() => {
        fetchMoreProducts();
    }, []);
    useEffect(() => {
        if (filters) {
            fetchProductsOnFiltersChange();
        }
    }, [filters]);
    const onFinishLogin = async () => {
        window.location.reload();
    };
    if (hasError) {
        return (_jsx(NotFound, { context: "shop-not-live", withLogin: true, onFinishLogin: onFinishLogin }, void 0));
    }
    if (!shopProducts || !productCategories || !tags || !shopInfo) {
        return (_jsx(ShopLayout, Object.assign({ mainFullHeight: true }, { children: _jsx(Loader, { centerInScreen: true }, void 0) }), void 0));
    }
    return (_jsx(ShopLayout, Object.assign({ navbar: {
            shopName: shopInfo.name,
            shopLinkName: shopInfo.linkName,
        } }, { children: _jsx(ShopProductsComponent, { isLoadingProducts: loadingProducts && !isFetchingMore, productCategories: productCategories, products: shopProducts, hasMore: hasMore, fetchMoreProducts: fetchMoreProducts, shopInfo: shopInfo, tags: tags }, void 0) }), void 0));
};
