import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { Button } from "../button";
import { FacebookLogo, GoogleIconLogo } from "../svg/images";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
const SocialButtons = ({ onFbClick, onGoogleClick, context, }) => {
    const t = useTranslate("components.authentication.socialButtons");
    const action = context === "login" ? t("login") : t("signUp");
    return (_jsxs("div", Object.assign({ className: "action-group" }, { children: [_jsx("div", Object.assign({ className: "action-item" }, { children: _jsx(Button, Object.assign({ bgColor: "custom", color: "gray", size: "custom", weight: "normal", className: classNames("action-btn", "google-button"), onClick: onGoogleClick }, { children: _jsx(GoogleIconLogo, { className: "google-icon" }, void 0) }), void 0) }), void 0), _jsx("div", Object.assign({ className: "action-item" }, { children: _jsx(Button, Object.assign({ size: "custom", weight: "normal", bgColor: "custom", className: classNames("action-btn", "fb-button"), onClick: onFbClick }, { children: _jsx(FacebookLogo, { className: "fb-icon" }, void 0) }), void 0) }), void 0)] }), void 0));
};
export default SocialButtons;
