import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from "react";
import { isEmpty, uniqueId } from "lodash-es";
import Sidebar from "../sidebar/Sidebar";
import ProjectItem from "../project-item/ProjectItem";
import { Button } from "../button";
import { IconBasket } from "../svg/icons";
import { useTranslate } from "@bluelibs/x-ui";
const CartSidebar = ({ items, removeFromBasket, isOpen, setIsOpen, onInquiry }) => {
    const t = useTranslate("components.cart");
    const toggleSidebar = () => setIsOpen((prevOpen) => !prevOpen);
    const closeSidebar = () => setIsOpen(false);
    return (_jsxs(Fragment, { children: [_jsxs(Button, Object.assign({ color: "primary", bgColor: "white", size: "custom", className: "cc-basket-sidebar__toggle-btn", onClick: toggleSidebar }, { children: [items.length ? _jsx("strong", Object.assign({ className: "cc-basket__label" }, { children: items.length }), void 0) : null, _jsx(IconBasket, {}, void 0), t("wishlist")] }), void 0), isOpen && (_jsx(Sidebar, Object.assign({ isMobile: AFRAME.utils.device.isMobile(), title: t("wishlist"), onClose: closeSidebar }, { children: isEmpty(items) ? (_jsx("div", { children: t("noProducts") }, void 0)) : (_jsxs("ul", Object.assign({ className: "cc-custom-list cc-object-list" }, { children: [items.map((item) => (_jsx(ProjectItem, Object.assign({ onRemove: () => removeFromBasket({
                                productId: item.id,
                                projectId: item.projectId,
                            }) }, item), uniqueId("object-item-")))), _jsx(Button, Object.assign({ onClick: onInquiry }, { children: t("sendInquiry") }), void 0)] }), void 0)) }), void 0))] }, void 0));
};
export default CartSidebar;
