import { useRef, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
const Portal = ({ children }) => {
    const isServer = typeof window === "undefined";
    const rootElemRef = useRef(isServer ? null : document.createElement("div"));
    useLayoutEffect(() => {
        const rootEl = rootElemRef.current;
        document.body.appendChild(rootEl);
        return () => {
            document.body.removeChild(rootEl);
        };
    }, []);
    return createPortal(children, rootElemRef.current);
};
export default Portal;
