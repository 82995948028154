import { gql } from "@apollo/client";
export const SHOP_GET_ALL_SHOPS = (lang) => gql `
    query EndUsersShopsGetAll($input: EndUsersShopsGetAllInput!) {
        EndUsersShopsGetAll(input: $input) {
            linkName
            name
            description {
                text(lang: ${lang})
            }
            logoUrl
        }
    }
`;
