import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import Loader from "../loader/Loader";
import Button from "./Button";
const ButtonLoader = React.forwardRef(({ isLoading = true, children, className, ...props }, ref) => {
    const buttonLoaderClasses = classNames({
        ["cc--is-loading"]: isLoading,
    }, className);
    return (_jsxs(Button, Object.assign({ ref: ref, className: buttonLoaderClasses }, props, { children: [children, isLoading && (_jsx("div", Object.assign({ className: "cc-button-loader" }, { children: _jsx(Loader, { size: "custom" }, void 0) }), void 0))] }), void 0));
});
export default ButtonLoader;
