import { gql } from "@apollo/client";
import { AcceptedLanguage } from "@root/api.types";
export const GET_DETAILS_ABOUT_ITEMS_IN_BASKET = (lang = AcceptedLanguage.en) => gql `
  query ($input: EndUsersGetDetailsAboutItemsInBasketInput!) {
    EndUsersGetDetailsAboutItemsInBasket(input: $input) {
      merchantId
      linkName
      shopName

      paymentMethods

      shippingOptions {
          id
          country
          text
          priceEur
      }

      products {
        id
        linkName
        status

        name {
            text(lang: ${lang})
          }

          description {
            text(lang: ${lang})
          }

        imageUrl
        totalPriceEur
        
        variants {
          quantity
          details {
            id
            name
            priceEur
            isOutOfStock
            isAvailable
            stock
          }
        }
      }
    }
  }
`;
