import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export const colorMaps = [
    { bg: '#F1F1F1', color: '#BABABA' },
    { bg: '#FF9999', color: '#FF4646' },
    { bg: '#FCC6A1', color: '#FF842F' },
    { bg: '#FFE1A0', color: '#FF4646' },
    { bg: '#FFFCAA', color: '#FFE1A0' },
    { bg: '#DCFF9E', color: '#B5FF34' },
    { bg: '#B5FF9E', color: '#64FF33' },
    { bg: '#A5FFD6', color: '#33FFA2' },
    { bg: '#A5FFEB', color: '#31FFD0' },
    { bg: '#B0F3FF', color: '#31E0FF' },
    { bg: '#B0D7FF', color: '#369AFF' },
    { bg: '#A5A3FF', color: '#3936FF' },
    { bg: '#CAA3FF', color: '#9140FF' },
    { bg: '#F3A5FF', color: '#E540FF' },
    { bg: '#FFA5E5', color: '#FF35C5' },
    { bg: '#FFBABA', color: '#FF3535' }
];
export const UserProfile = ({ theme }) => {
    return (_jsx("svg", Object.assign({ className: 'cc-user-profile', xmlns: "http://www.w3.org/2000/svg", width: "30", height: "30", viewBox: "0 0 30 30" }, { children: _jsxs("g", Object.assign({ id: "Group_1738", "data-name": "Group 1738", transform: "translate(9479 22696)" }, { children: [_jsx("circle", { id: "Ellipse_331", "data-name": "Ellipse 331", fill: theme ? theme.color : colorMaps[0].color, cx: "15", cy: "15", r: "15", transform: "translate(-9479 -22696)" }, void 0), _jsx("path", { id: "Icon_awesome-user-circle", "data-name": "Icon awesome-user-circle", fill: theme ? theme.bg : colorMaps[0].bg, d: "M15,.563a15,15,0,1,0,15,15A15,15,0,0,0,15,.563Zm0,5.806a5.323,5.323,0,1,1-5.323,5.323A5.323,5.323,0,0,1,15,6.369Zm0,20.806A11.59,11.59,0,0,1,6.139,23.05,6.744,6.744,0,0,1,12.1,19.433a1.48,1.48,0,0,1,.429.067A8.008,8.008,0,0,0,15,19.917a7.978,7.978,0,0,0,2.474-.417,1.48,1.48,0,0,1,.429-.067,6.744,6.744,0,0,1,5.958,3.617A11.59,11.59,0,0,1,15,27.175Z", transform: "translate(-9479 -22696.563)" }, void 0)] }), void 0) }), void 0));
};
