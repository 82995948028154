export const components = {
    "carousel-2d": {
        schema: {
            index: {
                type: "number",
                default: 0,
            },
        },
    },
    "fit-texture": {
        dependencies: ["geometry", "material"],
        schema: {
            type: "boolean",
            default: true,
        },
        /**
         * Called when component is attached and when component data changes.
         * Generally modifies the entity based on the data.
         */
        update: function () {
            if (this.data === false)
                return;
            const el = this.el;
            if (this.dimensions) {
                // If texture has already been loaded, and `fit-texture` was reset.
                this.applyTransformation();
            }
            else {
                const textureLoaded = function (e) {
                    const w = e.detail.texture.image.videoWidth || e.detail.texture.image.width;
                    const h = e.detail.texture.image.videoHeight || e.detail.texture.image.height;
                    // Don't apply transformation on incomplete info
                    if (h === 0 || w === 0)
                        return;
                    // Save dimensions for later updates to `fit-texture`, see above.
                    this.dimensions = { w: w, h: h };
                    this.applyTransformation();
                };
                el.addEventListener("materialvideoloadeddata", textureLoaded);
                el.addEventListener("materialtextureloaded", textureLoaded);
            }
        },
        applyTransformation: function () {
            const el = this.el;
            const geometry = el.getAttribute("geometry");
            // Use self.dimension data from previous texture/video loaded events
            const widthHeightRatio = this.dimensions.h / this.dimensions.w;
            if (geometry.width && geometry.height) {
                console.warn('Using `fit-texture` component on an element with both width and height. Therefore keeping width and changing height to fit the texture. If you want to manually set both width and height, set `fit-texture="false"`. ');
            }
            if (geometry.width) {
                el.setAttribute("height", geometry.width * widthHeightRatio);
            }
            else if (geometry.height) {
                el.setAttribute("width", geometry.height / widthHeightRatio);
            }
            else {
                // Neither width nor height is set.
                const tempWidth = 1.0;
                el.setAttribute("width", "" + tempWidth);
                el.setAttribute("height", tempWidth * widthHeightRatio);
            }
        },
    },
};
