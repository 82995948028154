import { jsx as _jsx } from "react/jsx-runtime";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { useEffect } from "react";
import { StringParam, useQueryParams } from "use-query-params";
export const CheckoutPage = () => {
    const [queryParams] = useQueryParams({
        success: StringParam,
        cancel: StringParam,
        merchantId: StringParam,
    });
    useEffect(() => {
        localStorage.setItem("checkout", JSON.stringify(queryParams));
        window.close();
    }, []);
    return _jsx(Loader, { centerInScreen: true }, void 0);
};
