var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { EJSON, ObjectId } from "@bluelibs/ejson";
import { AcceptedLanguage, } from "@root/api.types";
import { Smart } from "@bluelibs/smart";
import { ContainerInstance, Inject, Service } from "@bluelibs/core";
import { AppGuardian } from "@bundles/UIAppBundle/services";
import { createContext } from "react";
import autoBind from "auto-bind";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { GET_DETAILS_ABOUT_ITEMS_IN_BASKET } from "../queries/getDetailsAboutItemsInBasket.query";
import { UISessionService } from "@bluelibs/x-ui-session-bundle";
export const BasketContext = createContext(null);
let BasketSmart = class BasketSmart extends Smart {
    constructor(container) {
        super();
        this.container = container;
        this.state = {
            isLoading: true,
            isFetching: true,
            loaded: false,
            basket: [],
            initialised: false,
        };
        autoBind(this);
        this.uiSessionService = this.container.get(UISessionService);
    }
    static getContext() {
        return BasketContext;
    }
    async init() {
        this.setState({
            isLoading: true,
            isFetching: true,
            loaded: false,
            basket: [],
            initialised: false,
        });
        await this.uiSessionService.init();
        this.localStorageBasket =
            this.uiSessionService.state.basket ||
                EJSON.parse(localStorage.getItem("basket") || "[]");
        this.updateState({ initialised: true });
    }
    get basket() {
        const isEmbedded = this.uiSessionService.state.isEmbedded;
        if (isEmbedded) {
            const lastShopLinkName = this.uiSessionService.state.lastShopLinkName;
            return this.localStorageBasket.filter((item) => item.linkName === lastShopLinkName);
        }
        return this.localStorageBasket;
    }
    get isLoggedIn() {
        return this.guardian.state.isLoggedIn;
    }
    async onLogout() {
        return this.updateBasketItems();
    }
    async updateBasket() {
        if (this.isLoggedIn) {
            return this.updateBasketItems();
        }
        else {
            return this.updateBasketItems();
        }
    }
    async addToBasket(input) {
        const { productId, variantId, quantity, linkName } = input;
        if (this.localStorageBasket.some((product) => product.productId === productId && product.variantId === variantId)) {
            return; // already in cart
        }
        this.localStorageBasket.push({
            productId,
            variantId,
            quantity,
            linkName,
        });
        this.uiSessionService.set("basket", this.localStorageBasket, {
            persist: true,
        });
        await this.updateBasketItems();
    }
    async removeFromBasket(input) {
        const { productId, variantId } = input;
        this.localStorageBasket.splice(this.localStorageBasket.findIndex((prod) => prod.productId === productId && prod.variantId === variantId), 1);
        this.uiSessionService.set("basket", this.localStorageBasket, {
            persist: true,
        });
        const basket = this.state.basket.find((item) => item.products.some((product) => product.id === productId));
        await this.updateBasketItems();
        const hasDeletedLastProduct = this.state.basket.findIndex((item) => item.merchantId === basket.merchantId) === -1;
        return hasDeletedLastProduct;
    }
    async updateAfterCheckout(merchantId) {
        this.updateState({
            isLoading: true,
        });
        await this.updateBasket();
        const productsByMerchantId = this.state.basket.filter((item) => item.merchantId === merchantId)[0].products;
        const newBasket = this.state.basket.filter((item) => item.merchantId !== merchantId);
        this.updateState({
            basket: newBasket,
        });
        this.localStorageBasket = this.localStorageBasket.filter((item) => {
            return !productsByMerchantId.some((product) => product.id === item.productId);
        });
        this.uiSessionService.set("basket", this.localStorageBasket, {
            persist: true,
        });
        await this.updateBasket();
        this.updateState({
            isLoading: false,
        });
    }
    async changeQuantity(input) {
        const { productId, quantity, variantId } = input;
        const productIndex = this.localStorageBasket.findIndex((product) => product.productId === productId && product.variantId === variantId);
        const [product] = this.localStorageBasket.splice(productIndex, 1);
        const newProduct = Object.assign({}, product, { quantity });
        this.localStorageBasket.splice(productIndex, 0, newProduct);
        this.uiSessionService.set("basket", this.localStorageBasket, {
            persist: true,
        });
        await this.updateBasketItems();
    }
    async updateBasketItems() {
        const variables = {
            input: {
                products: this.localStorageBasket.map((item) => ({
                    productId: new ObjectId(item.productId),
                    variantId: item.variantId || "",
                    quantity: item.quantity || 1,
                })),
            },
        };
        this.updateState({ isFetching: true });
        const { data } = await this.apolloClient.query({
            query: GET_DETAILS_ABOUT_ITEMS_IN_BASKET(this.uiSessionService.state.currentLocale || AcceptedLanguage.en),
            variables,
            fetchPolicy: "network-only",
        });
        let basket = data.EndUsersGetDetailsAboutItemsInBasket;
        const products = basket.reduce((acc, item) => {
            return acc.concat(item.products);
        }, []);
        // TODO: this can be optimised, right now not a priority
        const lastShopLinkName = this.uiSessionService.state.lastShopLinkName;
        if (lastShopLinkName && this.uiSessionService.state.isEmbedded) {
            basket = basket.filter((item) => item.linkName === lastShopLinkName);
        }
        this.localStorageBasket = this.localStorageBasket.filter((item) => {
            return products.some((product) => product.id === item.productId &&
                product.variants.some((variant) => variant.details.id === item.variantId));
        });
        this.uiSessionService.set("basket", this.localStorageBasket, {
            persist: true,
        });
        this.updateState({
            basket,
            isFetching: false,
        });
    }
};
__decorate([
    Inject(),
    __metadata("design:type", typeof (_a = typeof UISessionService !== "undefined" && UISessionService) === "function" ? _a : Object)
], BasketSmart.prototype, "uiSessionService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_b = typeof AppGuardian !== "undefined" && AppGuardian) === "function" ? _b : Object)
], BasketSmart.prototype, "guardian", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_c = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _c : Object)
], BasketSmart.prototype, "apolloClient", void 0);
BasketSmart = __decorate([
    Service(),
    __metadata("design:paramtypes", [typeof (_d = typeof ContainerInstance !== "undefined" && ContainerInstance) === "function" ? _d : Object])
], BasketSmart);
export { BasketSmart };
