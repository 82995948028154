import { gql } from "@apollo/client";
export const SHOP_PRODUCTS_FIND_ONE = (lang) => gql `
    query($input: EndUsersGetShopProductInput!) {
        EndUsersGetShopProduct(input: $input) {
            id
            name {
                text(lang: ${lang})
            }
            description {
                text(lang: ${lang})
            }

            isComingSoon

            variants {
                priceEur
                slug
                name
                id
                isOutOfStock
                stock
            }

            imageUrls

            shopInfo {
                linkName
                name

                description {
                    text(lang: ${lang})
                }
                impressum {
                    text(lang: ${lang})
                }
                termsOfService {
                    text(lang: ${lang})
                }
                region
                logo

                phone
                email
            }
        }
    }
`;
