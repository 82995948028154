import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
/**
 * Simulate the fullscreen mode in mobile mode.
 * We'll show/hide some DIV's
 */
export const ProjectMobileFullscreen = () => {
    const isInFullScreenMode = () => {
        const doc = document;
        const isSwitchingToFullscreen = doc.webkitIsFullScreen || doc.mozFullScreen || doc.msFullscreenElement;
        return !!isSwitchingToFullscreen;
    };
    const switchFullscreen = (show) => {
        const elem = (document === null || document === void 0 ? void 0 : document.documentElement) || {};
        if (show) {
            if (elem.webkitRequestFullscreen) {
                try {
                    elem.webkitRequestFullscreen();
                }
                catch { }
            }
            if (elem.mozRequestFullScreen) {
                try {
                    elem.mozRequestFullScreen();
                }
                catch { }
            }
            if (elem.msRequestFullscreen) {
                try {
                    elem.msRequestFullscreen();
                }
                catch { }
            }
            if (elem.requestFullscreen) {
                try {
                    elem.requestFullscreen();
                }
                catch { }
            }
        }
        else {
            const doc = document || {};
            if (doc.webkitCancelFullScreen) {
                try {
                    doc.webkitCancelFullScreen();
                }
                catch { }
            }
            if (doc.webkitExitFullscreen) {
                try {
                    doc.webkitExitFullscreen();
                }
                catch { }
            }
            if (doc.mozCancelFullScreen) {
                try {
                    doc.mozCancelFullScreen();
                }
                catch { }
            }
            if (doc.mozExitFullscreen) {
                try {
                    doc.mozExitFullscreen();
                }
                catch { }
            }
            if (doc.msCancelFullScreen) {
                try {
                    doc.msCancelFullScreen();
                }
                catch { }
            }
            if (doc.msExitFullscreen) {
                try {
                    doc.msExitFullscreen();
                }
                catch { }
            }
            if (doc.msCancelFullScreen) {
                try {
                    doc.cancelFullScreen();
                }
                catch { }
            }
            if (doc.msExitFullscreen) {
                try {
                    doc.exitFullscreen();
                }
                catch { }
            }
        }
    };
    const handleVrBtnClick = () => {
        const isInFullScreen = isInFullScreenMode();
        // It should be reversed of current status of fullscreen mode
        switchFullscreen(!isInFullScreen);
    };
    const componentVisibility = (visible) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const divProjectBar = (_b = (_a = document.getElementsByClassName("cc-project-bar")) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : { style: {} };
        const btnProjectSidebarToggle = (_d = (_c = document.getElementsByClassName("cc-project-sidebar__toggle-btn")) === null || _c === void 0 ? void 0 : _c[0]) !== null && _d !== void 0 ? _d : { style: {} };
        const btnBasketSidebarToggle = (_f = (_e = document.getElementsByClassName("cc-basket-sidebar__toggle-btn")) === null || _e === void 0 ? void 0 : _e[0]) !== null && _f !== void 0 ? _f : { style: {} };
        const divJoystickPosRot = (_h = (_g = document.getElementsByClassName("joystickPosRot")) === null || _g === void 0 ? void 0 : _g[0]) !== null && _h !== void 0 ? _h : { style: {} };
        const divEnterVr = (_k = (_j = document.getElementsByClassName("a-enter-vr")) === null || _j === void 0 ? void 0 : _j[0]) !== null && _k !== void 0 ? _k : { style: {} };
        if (visible) {
            divProjectBar.style.display = "block";
            btnProjectSidebarToggle.style.display = "block";
            btnBasketSidebarToggle.style.display = "block";
            divJoystickPosRot.style.display = "block";
            divEnterVr.style.opacity = 1;
        }
        else {
            divProjectBar.style.display = "none";
            btnProjectSidebarToggle.style.display = "none";
            btnBasketSidebarToggle.style.display = "none";
            divJoystickPosRot.style.display = "none";
            divEnterVr.style.opacity = 0.3;
        }
    };
    const handleDocumentKeyDown = (event) => {
        const isEscapeBtnHit = event.key === "Escape";
        if (isEscapeBtnHit) {
            const isInFullScreen = isInFullScreenMode();
            // It should be reversed of current status of fullscreen mode
            switchFullscreen(!isInFullScreen);
        }
    };
    const handleFullscreenChange = () => {
        const isInFullScreen = isInFullScreenMode();
        // It should be reversed of current status of fullscreen mode
        componentVisibility(!isInFullScreen);
    };
    useEffect(() => {
        const MAX_POLL_COUNT = 20;
        const POLL_INTERVAL_MS = 1000;
        let cntrCurrPoll = 0;
        const pollVrBtnExists = setInterval(() => {
            if (cntrCurrPoll++ >= MAX_POLL_COUNT) {
                clearInterval(pollVrBtnExists);
            }
            const vrBtn = document.getElementsByClassName("a-enter-vr-button");
            if (vrBtn.length) {
                clearInterval(pollVrBtnExists);
                for (let i = 0; i < vrBtn.length; i++) {
                    vrBtn[i].addEventListener("click", handleVrBtnClick, false);
                }
                document.addEventListener("keydown", handleDocumentKeyDown);
                document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
                document.addEventListener("mozfullscreenchange", handleFullscreenChange);
                document.addEventListener("fullscreenchange", handleFullscreenChange);
                document.addEventListener("MSFullscreenChange", handleFullscreenChange);
            }
        }, POLL_INTERVAL_MS);
    }, []);
    // This is fullscreen check component and we don't need t have a visual output for this
    return _jsx(_Fragment, {}, void 0);
};
