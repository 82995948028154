import { gql } from "@apollo/client";
export const SEND_INQUIRY = gql `
  mutation ($input: EndUsersSendInquiryInput!) {
    EndUsersSendInquiry(input: $input)
  }
`;
export const SEND_INQUIRY_AUTHENTICATED = gql `
  mutation ($input: EndUsersSendInquiryAuthenticatedInput!) {
    EndUsersSendInquiryAuthenticated(input: $input)
  }
`;
