var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
/**
 * We customize the Guardian mostly because we have different models of Users, we fetch different data from server than the default and we
 * register them in different ways.
 *
 * Use the `useAppGuardian()` function instead of `useGuardian()`
 */
import { GuardianSmart, useSmart, UserLoggedInEvent, } from "@bluelibs/x-ui";
import { gql } from "@apollo/client";
import { Inject, Service } from "@bluelibs/core";
import { EndUserService } from "./EndUser.service";
let AppGuardian = class AppGuardian extends GuardianSmart {
    async load() {
        return super.load();
    }
    async login(username, password) {
        this.updateState({
            hasInvalidToken: false,
        });
        await this.storeToken(null);
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation login($input: LoginInput!) {
            login(input: $input) {
              token
            }
          }
        `,
            variables: {
                input: {
                    username,
                    password,
                },
            },
        })
            .then(async (response) => {
            const { token } = response.data.login;
            await this.eventManager.emit(new UserLoggedInEvent({ token }));
            // We await this as storing the token might be blocking
            await this.storeToken(token);
            await this.load();
            return token;
        });
    }
    async retrieveUser() {
        return this.apolloClient
            .query({
            query: gql `
          query me {
            me {
              _id
              email
              profile {
                firstName
                lastName
              }
              fullName
              roles
              merchant {
                _id
                avatar {
                  downloadUrl
                }
              }

              endUser {
                _id
                email
                firstName
                lastName
                phoneNumber
              }
            }
          }
        `,
            fetchPolicy: "network-only",
        })
            .then((response) => {
            return response.data.me;
        });
    }
    async register(input) {
        await this.endUserService.register(input);
        return "";
    }
};
__decorate([
    Inject(() => EndUserService),
    __metadata("design:type", typeof (_a = typeof EndUserService !== "undefined" && EndUserService) === "function" ? _a : Object)
], AppGuardian.prototype, "endUserService", void 0);
AppGuardian = __decorate([
    Service()
], AppGuardian);
export { AppGuardian };
/**
 * Use this instead `useGuardian()`
 * @returns
 */
export function useAppGuardian() {
    return useSmart(AppGuardian);
}
