import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { ShopSidebar } from "../sidebar";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
export const ShopsComponent = ({ shops, districts, }) => {
    const uiSession = useUISession();
    const lang = uiSession.get("currentLocale");
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(ShopSidebar, { withSearch: true, districts: districts }, void 0), _jsx("div", Object.assign({ className: styles["main-wrapper"] }, { children: _jsx("div", Object.assign({ className: styles["product-grid"] }, { children: shops.map((shop, index) => {
                        var _a;
                        return (_jsxs(Link, Object.assign({ to: `/${lang}/${shop.linkName}`, className: styles["product-card"] }, { children: [_jsx("div", Object.assign({ className: styles.imageWrapper }, { children: _jsx("div", Object.assign({ className: styles.image }, { children: _jsx("img", { src: (_a = shop.logoUrl) !== null && _a !== void 0 ? _a : "/public/assets/images/shop.png", alt: shop.name }, void 0) }), void 0) }), void 0), _jsxs("div", Object.assign({ className: styles.details }, { children: [_jsx("div", Object.assign({ className: styles.name }, { children: shop.name }), void 0), _jsx("div", Object.assign({ className: styles.description }, { children: shop.description.text }), void 0)] }), void 0)] }), index));
                    }) }), void 0) }), void 0)] }), void 0));
};
