import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import CheckoutItem from "../item/CheckoutItem";
const CheckoutList = ({ className, type = "horizontal", items, changeQuantity, }) => {
    const listClasses = classNames("cc-checkout-list", `cc--${type}`, className);
    return (_jsx("div", Object.assign({ className: listClasses }, { children: items.map((item) => {
            const onChangeQuantity = (quantity) => {
                changeQuantity(item.id, quantity);
            };
            return (_jsx("div", Object.assign({ className: "cc-checkout-list__item" }, { children: _jsx(CheckoutItem, Object.assign({}, item, { onChangeQuantity: onChangeQuantity }), void 0) }), item.id));
        }) }), void 0));
};
export default CheckoutList;
