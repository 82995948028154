import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "reflect-metadata";
import { XUIProvider } from "@bluelibs/x-ui";
import * as ReactDOM from "react-dom";
import { kernel } from "./kernel";
import "../assets/styles/index.scss";
import "./styles.scss";
import { Toaster } from "@bundles/UIAppBundle/containers";
import "react-toastify/dist/ReactToastify.css";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
ReactDOM.render(_jsxs(_Fragment, { children: [_jsx(XUIProvider, { loadingComponent: _jsx(Loader, { centerInScreen: true }, void 0), kernel: kernel }, void 0), _jsx(Toaster, {}, void 0)] }, void 0), document.getElementById("root"));
