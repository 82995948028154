import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { toast } from "react-toastify";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import { useSmart } from "@bluelibs/smart";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import { useMutation } from "@apollo/client";
import { CREATE_VR_AVATAR, DELETE_VR_AVATAR, } from "@bundles/UIAppBundle/mutations";
import Sidebar from "../sidebar/Sidebar";
import UploadVRAvatar from "../upload-vr-avatar/UploadVRAvatar";
const AvatarDetailsSidebar = ({ isOpen, onClose, }) => {
    var _a, _b;
    const t = useTranslate("components.avatarDetails");
    const projectSmart = useSmart(ProjectSmart);
    const [createVRAvatar] = useMutation(CREATE_VR_AVATAR);
    const [deleteVRAvatar] = useMutation(DELETE_VR_AVATAR);
    const onUploadVRAvatarFile = async (data) => {
        try {
            await createVRAvatar({ variables: { input: { avatarFile: data } } });
            toast.success(t("mediaUpdated"));
        }
        catch (err) {
            toast.error(err + "", { autoClose: false });
            throw err;
        }
    };
    const onDeleteVRAvatarFile = async (data) => {
        try {
            await deleteVRAvatar();
            toast.success(t("mediaRemoved"));
        }
        catch (err) {
            toast.error(err + "", { autoClose: false });
            throw err;
        }
    };
    if (!isOpen)
        return null;
    return (_jsx(Sidebar, Object.assign({ isMobile: AFRAME.utils.device.isMobile(), title: t("avatar"), onClose: onClose }, { children: _jsx(UploadVRAvatar, { onUploadFile: onUploadVRAvatarFile, onRemove: onDeleteVRAvatarFile, fileSrc: (_b = (_a = projectSmart.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl }, void 0) }), void 0));
};
export default AvatarDetailsSidebar;
