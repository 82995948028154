import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import { Button } from "../button";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
const maxMb = 20;
const maxSize = Math.pow(maxMb, 20);
const UploadVRAvatar = ({ fileSrc, onUploadFile, onRemove, className, }) => {
    const t = useTranslate("components.avatarDetails");
    const [vrAvatarSrc, setVRAvatarSrc] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const onDrop = useCallback(async (acceptedFiles) => {
        const currentFiles = acceptedFiles.map((file) => {
            file.preview = URL.createObjectURL(file);
            return file;
        });
        if (!Boolean(currentFiles.length))
            return;
        const [uploadedFile] = currentFiles;
        if (onUploadFile) {
            setIsLoading(true);
            try {
                await onUploadFile(uploadedFile);
                setVRAvatarSrc(uploadedFile.preview);
            }
            catch {
                // Do nothing
            }
            finally {
                setIsLoading(false);
            }
        }
    }, []);
    const handleRemove = async () => {
        if (onRemove) {
            setIsLoading(true);
            try {
                await onRemove(null, "remove");
                setVRAvatarSrc(null);
            }
            catch {
                // Do nothing
            }
            finally {
                setIsLoading(false);
            }
        }
    };
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        maxSize,
        accept: ".glb",
        multiple: false,
    });
    const uploadVRAvatarClasses = classNames("dropzone", className);
    const fileRejectionItems = fileRejections.map(({ errors }) => {
        return (_jsx(Fragment, { children: errors.map((e) => {
                if (e.code === "file-too-large") {
                    toast.error(t("fileTooLarge"));
                }
                else {
                    toast.error(e.message);
                }
            }) }, void 0));
    });
    useEffect(() => {
        if (fileSrc)
            setVRAvatarSrc(fileSrc);
    }, [fileSrc]);
    return (_jsxs("div", Object.assign({ className: uploadVRAvatarClasses }, { children: [_jsxs("div", { children: ["* ", t("supportedImageFormats"), ": GLB file"] }, void 0), _jsxs("div", Object.assign({ className: `cc--flex ${"dropzone-wrapper"}` }, { children: [isLoading && (_jsx("div", Object.assign({ className: "image" }, { children: _jsx("div", Object.assign({ className: "loader" }, { children: _jsx(Loader, { size: "small" }, void 0) }), void 0) }), void 0)), _jsx("div", Object.assign({}, getRootProps(), { className: "dropzone-wrapper" }, { children: vrAvatarSrc ? (_jsx(_Fragment, { children: _jsx(Button, Object.assign({ variant: "outlined", color: "primary", size: "small", disabled: isLoading, onClick: handleRemove }, { children: t("remove") }), void 0) }, void 0)) : (_jsxs(_Fragment, { children: [_jsx("input", Object.assign({}, getInputProps()), void 0), _jsx(Button, Object.assign({ variant: "outlined", color: "primary", size: "small", disabled: isLoading, onClick: onDrop }, { children: t("upload") }), void 0)] }, void 0)) }), void 0)] }), void 0), fileRejectionItems] }), void 0));
};
export default UploadVRAvatar;
