import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { useRouter, useUIComponents, useUISession } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { PROJECTS_FIND } from "@bundles/UIAppBundle/queries/projectsFind.query";
import env from "@root/startup/env";
import * as Ant from "antd";
import { useEffect, useState } from "react";
export const HomePage = () => {
    const router = useRouter();
    const uiSession = useUISession();
    const UIComponents = useUIComponents();
    const linkName = uiSession.get("projectLinkName");
    useEffect(() => {
        if (!linkName) {
            return window.location.replace(env.LANDING_APP_URL);
        }
        router.go(Routes.PROJECT, {
            params: {
                linkName,
            },
        });
    }, []);
    return _jsx(Loader, { centerInScreen: true }, void 0);
    return linkName ? null : _jsx(UIComponents.NotFound, {}, void 0);
    const [projects, setProjects] = useState([]);
    const { loading, error } = useQuery(PROJECTS_FIND, {
        onCompleted: (data) => setProjects(data.ProjectsFind),
    });
    const goToProject = (linkName) => router.go(Routes.PROJECT, {
        params: {
            linkName,
        },
    });
    if (loading)
        return _jsx(Loader, { centerInScreen: true }, void 0);
    if (error)
        return _jsx(UIComponents.Error, { error: error }, void 0);
    return (_jsx(Ant.List, { dataSource: projects, renderItem: (item) => (_jsx("a", Object.assign({ href: "#", onClick: () => goToProject(item.linkName) }, { children: _jsx(Ant.List.Item, { children: item.name }, void 0) }), void 0)) }, void 0));
};
export default HomePage;
