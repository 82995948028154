import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Button } from "@bundles/UIAppBundle/components/button";
import { IconMinus, IconPlus } from "@bundles/UIAppBundle/components/svg/icons";
const Quantity = ({ defaultQuantity = 0, onChangeQuantity, maxQuantity, className, }) => {
    const [quantity, setQuantity] = useState(0);
    const handleOnClickDecrement = () => {
        if (!setQuantity)
            return null;
        setQuantity(quantity - 1);
        if (onChangeQuantity)
            onChangeQuantity(quantity - 1);
    };
    const handleOnClickIncrement = () => {
        if (!setQuantity)
            return null;
        setQuantity(quantity + 1);
        if (onChangeQuantity)
            onChangeQuantity(quantity + 1);
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuantity(+value);
        if (onChangeQuantity)
            onChangeQuantity(+value);
        if (maxQuantity && value >= maxQuantity) {
            setQuantity(maxQuantity);
            if (onChangeQuantity)
                onChangeQuantity(maxQuantity);
        }
    };
    useEffect(() => setQuantity(defaultQuantity), [defaultQuantity]);
    return (_jsxs("div", Object.assign({ className: classNames("cc-quantity-container", className) }, { children: [_jsx("div", { children: _jsx(Button, Object.assign({ bgColor: "custom", color: "custom", onClick: handleOnClickDecrement, size: "custom", className: "cc-quantity-action__button", disabled: quantity === 1 }, { children: _jsx(IconMinus, {}, void 0) }), void 0) }, void 0), _jsx("div", Object.assign({ className: "cc-quantity-input__wrapper" }, { children: _jsx("input", { className: "cc-quantity-input", type: "number", value: quantity, onChange: handleInputChange }, void 0) }), void 0), _jsx("div", { children: _jsx(Button, Object.assign({ bgColor: "custom", color: "custom", onClick: handleOnClickIncrement, size: "custom", className: "cc-quantity-action__button", disabled: Boolean(maxQuantity && quantity >= maxQuantity) }, { children: _jsx(IconPlus, {}, void 0) }), void 0) }, void 0)] }), void 0));
};
export default Quantity;
