import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import styles from "./styles.module.scss";
import { createContext, useCallback, useContext, useEffect, useState, } from "react";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import { removeFalsyValues } from "@bundles/UIAppBundle/utils/removeFalsyValues";
import { debounce } from "lodash-es";
import { ShopButton } from "../button";
import classNames from "classnames";
import { IconArrowDown, IconArrowRight, } from "@bundles/UIAppBundle/components/svg";
export const ShopSidebarContext = createContext(null);
export const ShopSidebarProvider = ({ children }) => {
    const [filters, setFilters] = useState(null);
    const [text, setText] = useState("");
    const [query, setQuery] = useQueryParams({
        category: StringParam,
        bundesland: StringParam,
        text: StringParam,
        tags: ArrayParam,
    });
    useEffect(() => {
        if (!filters) {
            return;
        }
        const newFilters = removeFalsyValues(filters);
        setQuery(newFilters);
    }, [filters]);
    useEffect(() => {
        setFilters(query);
        setText(query.text);
    }, []);
    return (_jsx(ShopSidebarContext.Provider, Object.assign({ value: {
            filters,
            setFilters,
            text,
            setText,
        } }, { children: children }), void 0));
};
export const useShopSidebarContext = () => {
    return useContext(ShopSidebarContext);
};
export const ShopSidebar = ({ withSearch, productCategories, districts, tags, }) => {
    const t = useTranslate("components.shopSidebar");
    const { filters, setFilters, text, setText } = useContext(ShopSidebarContext);
    const [expandedCategories, setExpandedCategories] = useState(() => {
        if (!filters.category || !productCategories) {
            return {};
        }
        const expandedCategories = productCategories.reduce((acc, category) => {
            if (category.subcategories.some((subcategory) => { var _a; return subcategory.name.toLowerCase() === ((_a = filters.category) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })) {
                acc[category.name] = true;
            }
            return acc;
        }, {});
        return expandedCategories;
    });
    const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(false);
    const [isDistrictsExpanded, setIsDistrictsExpanded] = useState(false);
    const [isTagsExpanded, setIsTagsExpanded] = useState(false);
    const toggleSubcategories = (category) => {
        setExpandedCategories((prevState) => ({
            ...prevState,
            [category]: !prevState[category],
        }));
    };
    const setValue = useCallback((key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);
    const debouncedSetValue = debounce(setValue, 300);
    const setTextDebounced = useCallback((value) => {
        setText(value);
        debouncedSetValue("text", value);
    }, []);
    const handleTagsSelect = (tag) => {
        const tags = filters.tags || [];
        if (tags.includes(tag)) {
            setValue("tags", tags.filter((t) => t !== tag));
            return;
        }
        setValue("tags", [...tags, tag]);
    };
    const handleCategorySelect = (category) => {
        var _a;
        if (((_a = filters.category) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (category === null || category === void 0 ? void 0 : category.toLowerCase())) {
            setValue("category", null);
            return;
        }
        setValue("category", category.toLowerCase());
    };
    const handleDistrictSelect = (district) => {
        var _a, _b, _c;
        if (((_b = (_a = filters.bundesland) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a)) === ((_c = district === null || district === void 0 ? void 0 : district.toLowerCase) === null || _c === void 0 ? void 0 : _c.call(district))) {
            setValue("bundesland", null);
            return;
        }
        setValue("bundesland", district.toLowerCase());
    };
    const onSearch = (e) => {
        setTextDebounced(e.target.value);
    };
    return (_jsxs("div", Object.assign({ className: styles.sidebar }, { children: [withSearch && (_jsx("div", Object.assign({ className: styles.box }, { children: _jsxs("div", Object.assign({ className: styles.scrollable }, { children: [_jsx("span", Object.assign({ className: styles.toggleSection }, { children: t("search") }), void 0), _jsx("input", { type: "text", value: text, onChange: onSearch }, void 0)] }), void 0) }), void 0)), (productCategories === null || productCategories === void 0 ? void 0 : productCategories.length) ? (_jsxs("div", Object.assign({ className: styles.box }, { children: [_jsx("div", { className: styles.spacer }, void 0), _jsx("hr", {}, void 0), _jsx("div", { className: styles.spacer }, void 0), _jsxs("div", Object.assign({ onClick: () => setIsCategoriesExpanded(!isCategoriesExpanded), className: styles.toggler }, { children: [_jsx("span", Object.assign({ className: styles.toggleButton }, { children: isCategoriesExpanded ? _jsx(IconArrowDown, {}, void 0) : _jsx(IconArrowRight, {}, void 0) }), void 0), _jsx("span", Object.assign({ className: styles.toggleSection }, { children: t("categories") }), void 0)] }), void 0), isCategoriesExpanded && (_jsx("div", Object.assign({ className: styles.scrollable }, { children: productCategories.map((category) => {
                            var _a;
                            return (_jsxs("div", Object.assign({ className: styles.categoryWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.categoryRow }, { children: [_jsx(ShopButton, Object.assign({ className: ((_a = filters.category) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                                                    category.name.toLowerCase()
                                                    ? styles.active
                                                    : "", onClick: () => handleCategorySelect(category.name) }, { children: category.name }), void 0), category.subcategories.length > 0 && (_jsx("button", Object.assign({ className: styles.toggleButton, onClick: () => toggleSubcategories(category.name) }, { children: expandedCategories[category.name] ? "-" : "+" }), void 0))] }), void 0), expandedCategories[category.name] && (_jsx("div", Object.assign({ className: styles.subcategories }, { children: category.subcategories.map((subcategory) => {
                                            var _a;
                                            return (_jsx(ShopButton, Object.assign({ className: ((_a = filters.category) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                                                    subcategory.name.toLowerCase()
                                                    ? styles.active
                                                    : "", onClick: () => handleCategorySelect(subcategory.name) }, { children: subcategory.name }), subcategory.id));
                                        }) }), void 0))] }), category.id));
                        }) }), void 0))] }), void 0)) : null, (districts === null || districts === void 0 ? void 0 : districts.length) ? (_jsxs("div", Object.assign({ className: styles.box }, { children: [_jsx("div", { className: styles.spacer }, void 0), _jsx("hr", {}, void 0), _jsx("div", { className: styles.spacer }, void 0), _jsxs("div", Object.assign({ onClick: () => setIsDistrictsExpanded(!isDistrictsExpanded), className: styles.toggler }, { children: [_jsx("span", Object.assign({ className: styles.toggleButton }, { children: isDistrictsExpanded ? _jsx(IconArrowDown, {}, void 0) : _jsx(IconArrowRight, {}, void 0) }), void 0), _jsx("span", Object.assign({ className: styles.toggleSection }, { children: t("districts") }), void 0)] }), void 0), isDistrictsExpanded && (_jsx("div", Object.assign({ className: classNames(styles["scrollable"]) }, { children: districts.map((district, index) => {
                            var _a, _b;
                            return (_jsx(ShopButton, Object.assign({ className: ((_b = (_a = filters.bundesland) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a)) ===
                                    district.name.toLowerCase()
                                    ? styles.active
                                    : "", onClick: () => handleDistrictSelect(district.name) }, { children: district.name }), index));
                        }) }), void 0))] }), void 0)) : null, (tags === null || tags === void 0 ? void 0 : tags.length) ? (_jsxs("div", Object.assign({ className: styles.box }, { children: [_jsx("div", { className: styles.spacer }, void 0), _jsx("hr", {}, void 0), _jsx("div", { className: styles.spacer }, void 0), _jsxs("div", Object.assign({ onClick: () => setIsTagsExpanded(!isTagsExpanded), className: styles.toggler }, { children: [_jsx("span", Object.assign({ className: styles.toggleButton }, { children: isTagsExpanded ? _jsx(IconArrowDown, {}, void 0) : _jsx(IconArrowRight, {}, void 0) }), void 0), _jsx("span", Object.assign({ className: styles.toggleSection }, { children: t("tags") }), void 0)] }), void 0), isTagsExpanded && (_jsx("div", Object.assign({ className: classNames(styles["scrollable"]) }, { children: tags.map((tag, index) => {
                            var _a;
                            return (_jsx(ShopButton, Object.assign({ onClick: () => handleTagsSelect(tag), className: ((_a = filters.tags) === null || _a === void 0 ? void 0 : _a.includes(tag)) ? styles.active : "" }, { children: tag }), index));
                        }) }), void 0))] }), void 0)) : null] }), void 0));
};
