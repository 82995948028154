import { gql } from "@apollo/client";
export const GET_SHOP_INFO = (lang) => gql `
  query ($input: EndUsersGetShopInfoInput!) {
    EndUsersGetShopInfo(input: $input) {
        name
        linkName
        phone
        email
        description {
          text(lang: ${lang})
        }
        impressum {
          text(lang: ${lang})
        }
        termsOfService {
          text(lang: ${lang})
        }
        region
        logo
    }
  }
`;
