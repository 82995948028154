import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useMemo, useState } from "react";
import { useAppGuardian } from "@bundles/UIAppBundle/services";
import { uniqueId } from "lodash-es";
import Dropdown from "../dropdown/Dropdown";
import { Button } from "../button";
import { IconCamera, IconChat, IconDotsH, IconLeave, IconMic, IconProfile, IconBasket, IconSettings, } from "../svg/icons";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { AcceptedLanguage, UserRole } from "@root/api.types";
import { useSmart, useTranslate } from "@bluelibs/x-ui";
import { ToggleSwitch } from "../form";
import IconMicOff from "../svg/icons/IconMicOff";
import IconVideoOff from "../svg/icons/IconVideoOff";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import AvatarDetailsSidebar from "../avatar-details/AvatarDetailsSidebar";
const ProjectActionBar = ({ onLogin, onLogout, onInquiry, onLeave, }) => {
    const t = useTranslate("components.actionBar");
    const [avatarSidebarVisibility, setAvatarSidebarVisibility] = useState(false);
    const session = useUISession();
    const projectSmart = useSmart(ProjectSmart);
    const guardian = useAppGuardian();
    const state = projectSmart.state;
    const language = session.get("currentLocale");
    const handleOnAvatarClick = () => {
        setAvatarSidebarVisibility((prev) => !prev);
    };
    const actionItems = [
        projectSmart.canUseVideo()
            ? {
                icon: state.hasCamera ? IconCamera : IconVideoOff,
                label: t("actionItems.video"),
                onClick: projectSmart.switchCamera,
            }
            : null,
        projectSmart.canUseAudio()
            ? {
                icon: state.hasMicrophone ? IconMic : IconMicOff,
                label: t("actionItems.voice"),
                onClick: projectSmart.switchMicrophone,
            }
            : null,
        projectSmart.canUseChat()
            ? {
                icon: IconChat,
                label: t("actionItems.chat"),
                onClick: projectSmart.switchIsChatting,
            }
            : null,
        {
            icon: IconBasket,
            label: t("actionItems.inquiry"),
            onClick: onInquiry,
        },
        {
            icon: IconLeave,
            label: t("actionItems.leave"),
            type: "red",
            onClick: onLeave,
        },
        {
            icon: IconDotsH,
            label: t("actionItems.more"),
            isDropdown: true,
            onClick: (e) => e.preventDefault(),
        },
    ];
    const dropdownItems = [
        // {
        //   icon: IconInvite,
        //   label: "Invite",
        //   url: "/",
        // },
        // {
        //   icon: IconSettings,
        //   label: "Settings",
        //   url: "/",
        // },
        ...(guardian.state.isLoggedIn && state.role === UserRole.ENDUSER
            ? [
                {
                    icon: IconSettings,
                    label: t("avatar"),
                    url: "#",
                    onClick: handleOnAvatarClick,
                },
            ]
            : []),
        guardian.state.isLoggedIn
            ? {
                icon: IconProfile,
                label: t("signOut"),
                url: "#",
                onClick: onLogout,
            }
            : {
                icon: IconProfile,
                label: t("signIn"),
                url: "#",
                onClick: onLogin,
            },
        // TODO: maybe make it a dropdown inside a dropdown?
        // {
        //   icon: IconChat,
        //   label: "Set Language - English",
        //   url: "#",
        //   onClick: () => onLanguageChange(AcceptedLanguage.en),
        // },
        // {
        //   icon: IconChat,
        //   label: "Set Language - Deutsch",
        //   url: "#",
        //   onClick: () => onLanguageChange(AcceptedLanguage.de),
        // },
        {
            Component: () => {
                const [lang, setLang] = useState(language);
                const onChange = (checked) => {
                    const newLang = checked ? AcceptedLanguage.de : AcceptedLanguage.en;
                    projectSmart.onLanguageChange(newLang);
                    setLang(newLang);
                };
                return (_jsxs(Fragment, { children: [_jsx(ToggleSwitch, { onChange: (e) => onChange(e.target.checked), defaultChecked: lang === AcceptedLanguage.de }, void 0), _jsx("span", { children: lang === AcceptedLanguage.en ? "Deutsch" : "English" }, void 0)] }, void 0));
            },
        },
        // {
        //   icon: IconReport,
        //   label: "Report",
        //   url: "/",
        // },
    ];
    const items = useMemo(() => actionItems
        .filter(Boolean)
        .map(({ icon, label, type = "primary", isDropdown = false, onClick }) => {
        const Icon = icon;
        return (_jsxs("li", Object.assign({ className: "cc-project-bar__item" }, { children: [isDropdown ? (_jsx(Dropdown, { headerButton: _jsx(Button, Object.assign({ variant: "outlined", color: type, bgColor: type, size: "custom", className: "cc-project-bar__action-btn" }, { children: _jsx(Icon, {}, void 0) }), void 0), items: dropdownItems }, void 0)) : (_jsx(Button, Object.assign({ variant: "outlined", color: type, bgColor: type, size: "custom", className: "cc-project-bar__action-btn", onClick: onClick }, { children: _jsx(Icon, {}, void 0) }), void 0)), _jsx("span", Object.assign({ className: "cc--center" }, { children: label }), void 0)] }), uniqueId("action-item-")));
    }), [state, language]);
    return (_jsx("div", Object.assign({ className: "cc-project-bar" }, { children: _jsxs("ul", Object.assign({ className: "cc--flex cc-custom-list cc-project-bar__action-list" }, { children: [items, _jsx(AvatarDetailsSidebar, { isOpen: avatarSidebarVisibility, onClose: () => setAvatarSidebarVisibility(false) }, void 0)] }), void 0) }), void 0));
};
export default ProjectActionBar;
