import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { AssetMediaRenderType } from "@root/api.types";
import { Icon2D, Icon360, Icon3D, IconTrash } from "../svg/icons";
const iconAssetTypeEnum = {
    [AssetMediaRenderType.IMAGE_2D]: {
        icon: Icon2D,
        label: "2D Image",
    },
    [AssetMediaRenderType.IMAGE_2D_CAROUSEL]: {
        icon: Icon2D,
        label: "2D Carousel",
    },
    [AssetMediaRenderType.IMAGE_360]: {
        icon: Icon360,
        label: "Image 360",
    },
    [AssetMediaRenderType.IMAGE_360_CAROUSEL]: {
        icon: Icon360,
        label: "360 Carousel",
    },
    [AssetMediaRenderType.ITEM_3D]: {
        icon: Icon3D,
        label: "3D Image",
    },
};
const ProjectItem = ({ isActive = false, onClick, image, name, onRemove, }) => {
    // const IconAsset = iconAssetTypeEnum[type].icon || undefined;
    const listClasses = classNames("cc--flex cc-object-item", {
        active: isActive,
    });
    return (_jsxs("li", Object.assign({ className: listClasses, onClick: onClick }, { children: [_jsx("div", Object.assign({ className: "cc-object-item__avatar" }, { children: _jsx("img", { src: image, className: "cc--of" }, void 0) }), void 0), _jsx("div", Object.assign({ className: "cc--text-color-primary cc--text-bold cc-object-item__name" }, { children: _jsx("span", Object.assign({ className: "cc--text-ellipsis" }, { children: name }), void 0) }), void 0), onRemove && (_jsx("button", Object.assign({ className: "cc-button cc--filled cc--primary cc--bg-white cc-object-item__remove", onClick: onRemove }, { children: _jsx("span", { children: _jsx(IconTrash, {}, void 0) }, void 0) }), void 0))] }), void 0));
};
export default ProjectItem;
