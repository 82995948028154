import { gql } from "@apollo/client";
export const GET_ALL_MESSAGES = gql `
  query ($input: PublicChatGetAllMessagesInput!) {
    PublicChatGetAllMessages(input: $input) {
      text
      _id

      session {
        firstName
        lastName
      }

      replyForSession {
        firstName
        lastName
      }

      replyForSessionId
      createdAt
    }
  }
`;
