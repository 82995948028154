import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { uniqueId } from "lodash-es";
const Input = React.forwardRef(({ noMarginBottom, className, name = "input-", type = "text", value, label, error, sizeStyle = "normal", ...props }, ref) => {
    const inputClasses = classNames("cc-input", `size--${sizeStyle}`, className);
    const id = uniqueId(`${name}-`);
    return (_jsx("div", Object.assign({ className: "cc-input-container" }, { children: _jsx("input", Object.assign({ id: id, name: name, ref: ref, type: type, value: value !== null ? value : undefined, className: inputClasses }, props), void 0) }), void 0));
});
export default Input;
