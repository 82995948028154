import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
export const AvatarTemplate = ({ templateId, avatarUrl, }) => {
    const AvatarTemplateCamera = (_jsx("template", { dangerouslySetInnerHTML: {
            __html: `
<a-entity class="avatar" networked-audio-source>
  <a-plane
    color="#FFF"
    width="1.33"
    height="1"
    position="0 .6 0"
    material="side: front"
    networked-video-source
  ></a-plane>
  <a-plane
    color="#FFF"
    width="1.33"
    height="1"
    position="0 .6 0"
    material="side: back"
    networked-video-source
  ></a-plane>
</a-entity>
`,
        }, id: `${templateId}-camera` }, void 0));
    let AvatarContainer;
    if (avatarUrl) {
        AvatarContainer = (_jsx("template", { dangerouslySetInnerHTML: {
                __html: `
<a-entity class="avatar" networked-audio-source>
  <a-gltf-model rotation="0 180 0" src="#avatar-glb"></a-gltf-model>
</a-entity>
`,
            }, id: templateId }, void 0));
    }
    else {
        AvatarContainer = (_jsx("template", { dangerouslySetInnerHTML: {
                __html: `
<a-entity class="avatar" networked-audio-source>
  <a-sphere class="head" scale="0.45 0.5 0.4"></a-sphere>
  <a-entity class="face" position="0 0.05 0">
    <a-sphere
      class="eye"
      color="#efefef"
      position="0.16 0.1 -0.35"
      scale="0.12 0.12 0.12"
    >
      <a-sphere
        class="pupil"
        color="#000"
        position="0 0 -1"
        scale="0.2 0.2 0.2"
      ></a-sphere>
    </a-sphere>
    <a-sphere
      class="eye"
      color="#efefef"
      position="-0.16 0.1 -0.35"
      scale="0.12 0.12 0.12"
    >
      <a-sphere
        class="pupil"
        color="#000"
        position="0 0 -1"
        scale="0.2 0.2 0.2"
      ></a-sphere>
    </a-sphere>
  </a-entity>
</a-entity>
`,
            }, id: templateId }, void 0));
    }
    return (_jsxs(_Fragment, { children: [AvatarTemplateCamera, AvatarContainer, avatarUrl && (_jsx("a-asset-item", { id: "avatar-glb", src: avatarUrl }, void 0))] }, void 0));
};
