var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { EJSON } from "@bluelibs/ejson";
import { UISessionService } from "@bluelibs/x-ui-session-bundle";
import { AcceptedLanguage } from "@root/api.types";
import { Smart } from "@bluelibs/smart";
import { ContainerInstance, Inject, Service } from "@bluelibs/core";
import { AppGuardian } from "@bundles/UIAppBundle/services";
import { createContext } from "react";
import autoBind from "auto-bind";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { GET_DETAILS_ABOUT_ITEMS_IN_WISHLIST } from "../queries/getDetailsAboutItemsInWishlist.query";
import { ADD_PRODUCT_TO_WISHLIST, CHANGE_QUANTITY_WISHLIST_ITEM, REMOVE_PRODUCT_FROM_WISHLIST, SEND_INQUIRY, SEND_INQUIRY_AUTHENTICATED, } from "../mutations";
import { END_USERS_SEND_INQUIRY_AND_CREATE_ACCOUNT } from "../mutations/EndUsersSendInquiryAndCreateAccount.mutation";
export const WishlistContext = createContext(null);
let WishlistSmart = class WishlistSmart extends Smart {
    constructor(container) {
        super();
        this.container = container;
        this.state = {
            isLoading: true,
            wishlist: [],
            initialised: false,
        };
        autoBind(this);
        this.uiSessionService = this.container.get(UISessionService);
    }
    static getContext() {
        return WishlistContext;
    }
    async init() {
        this.setState({
            isLoading: false,
            wishlist: [],
            initialised: false,
        });
        await this.uiSessionService.init();
        this.localStorageWishlist =
            this.uiSessionService.state.wishlist ||
                EJSON.parse(localStorage.getItem("wishlist") || "{}");
        this.updateState({ initialised: true });
    }
    get merchantId() {
        return this.uiSessionService.state.merchantId;
    }
    get isLoggedIn() {
        return this.guardian.state.isLoggedIn;
    }
    async onLogout() {
        return this.updateWishlistItems();
    }
    updateWishlist() {
        if (this.isLoggedIn) {
            this.updateWishlistItems();
        }
        else {
            this.updateWishlistItems();
        }
    }
    async addToWishlistDatabase(input) {
        await this.apolloClient.mutate({
            mutation: ADD_PRODUCT_TO_WISHLIST,
            variables: {
                input,
            },
        });
        await this.updateWishlistItems();
    }
    async removeFromWishlistDatabase(input) {
        const { productId } = input;
        await this.apolloClient.mutate({
            mutation: REMOVE_PRODUCT_FROM_WISHLIST,
            variables: {
                input: {
                    productId,
                },
            },
        });
        await this.updateWishlistItems();
    }
    async addToWishlistLocalStorage(input) {
        const { productId, projectId } = input;
        if (!this.localStorageWishlist[this.merchantId]) {
            this.localStorageWishlist[this.merchantId] = [];
        }
        if (this.localStorageWishlist[this.merchantId].some((product) => product.productId === productId && product.projectId === projectId))
            return;
        this.localStorageWishlist[this.merchantId].push({
            productId,
            projectId,
            quantity: 1,
        });
        this.uiSessionService.set("wishlist", this.localStorageWishlist, {
            persist: true,
        });
        await this.updateWishlistItems();
    }
    async removeFromWishlistLocalStorage(input) {
        const { productId } = input;
        this.localStorageWishlist[this.merchantId].splice(this.localStorageWishlist[this.merchantId].findIndex((prod) => prod.productId === productId), 1);
        this.uiSessionService.set("wishlist", this.localStorageWishlist, {
            persist: true,
        });
        this.updateWishlistItems();
    }
    async changeQuantityDatabase(productId, quantity) {
        await this.apolloClient.mutate({
            mutation: CHANGE_QUANTITY_WISHLIST_ITEM,
            variables: {
                input: {
                    productId,
                    quantity,
                },
            },
        });
        await this.updateWishlistItems();
    }
    async changeQuantityLocalStorage(productId, quantity) {
        const productIndex = this.localStorageWishlist[this.merchantId].findIndex((product) => product.productId === productId);
        const [product] = this.localStorageWishlist[this.merchantId].splice(productIndex, 1);
        const newProduct = Object.assign({}, product, { quantity });
        this.localStorageWishlist[this.merchantId].splice(productIndex, 0, newProduct);
        this.uiSessionService.set("wishlist", this.localStorageWishlist, {
            persist: true,
        });
    }
    async changeQuantity(productId, quantity) {
        if (this.isLoggedIn) {
            return this.changeQuantityDatabase(productId, quantity);
        }
        else {
            return this.changeQuantityLocalStorage(productId, quantity);
        }
    }
    async addToWishlist(input) {
        if (this.isLoggedIn) {
            return this.addToWishlistDatabase(input);
        }
        else {
            return this.addToWishlistLocalStorage(input);
        }
    }
    async removeFromWishlist(input) {
        if (this.isLoggedIn) {
            return this.removeFromWishlistDatabase(input);
        }
        else {
            return this.removeFromWishlistLocalStorage(input);
        }
    }
    async updateWishlistItems() {
        const variables = {
            input: {},
        };
        if (!this.merchantId)
            return;
        this.updateState({ isLoading: true });
        if (!this.localStorageWishlist[this.merchantId]) {
            this.localStorageWishlist[this.merchantId] = [];
            this.uiSessionService.set("wishlist", this.localStorageWishlist, {
                persist: true,
            });
        }
        if (this.isLoggedIn) {
            variables["input"] = {
                merchantId: this.merchantId,
            };
        }
        else {
            variables["input"] = {
                productIds: this.localStorageWishlist[this.merchantId].map((item) => item.productId),
            };
        }
        const { data } = await this.apolloClient.query({
            query: GET_DETAILS_ABOUT_ITEMS_IN_WISHLIST(this.uiSessionService.state.currentLocale || AcceptedLanguage.en),
            variables,
            fetchPolicy: "network-only",
        });
        if (this.isLoggedIn) {
            this.updateState({
                wishlist: data.EndUsersGetDetailsAboutItemsInWishlist,
                isLoading: false,
            });
        }
        else {
            this.updateState({
                wishlist: data.EndUsersGetDetailsAboutItemsInWishlist.map((item) => ({
                    ...item,
                    quantity: this.localStorageWishlist[this.merchantId].find((i) => i.productId === item.product._id).quantity,
                })),
                isLoading: false,
            });
        }
    }
    async sendInquiryLoggedIn(data) {
        await this.apolloClient.mutate({
            mutation: SEND_INQUIRY_AUTHENTICATED,
            variables: {
                input: {
                    merchantId: this.merchantId,
                    ...data,
                },
            },
        });
    }
    async sendInquiryLocalStorage(data) {
        await this.apolloClient.mutate({
            mutation: SEND_INQUIRY,
            variables: {
                input: {
                    ...data,
                    merchantId: this.merchantId,
                    products: this.localStorageWishlist[this.merchantId].map(({ projectId, productId, quantity }) => ({
                        projectId,
                        productId,
                        quantity,
                    })),
                },
            },
        });
        delete this.localStorageWishlist[this.merchantId];
        this.uiSessionService.set("wishlist", this.localStorageWishlist, {
            persist: true,
        });
    }
    async sendInquiry(data) {
        if (this.isLoggedIn) {
            return this.sendInquiryLoggedIn(data);
        }
        else {
            return this.sendInquiryLocalStorage(data);
        }
    }
    async sendInquiryAndCreateAccount(data) {
        await this.apolloClient.mutate({
            mutation: END_USERS_SEND_INQUIRY_AND_CREATE_ACCOUNT,
            variables: {
                input: {
                    merchantId: this.merchantId,
                    ...data,
                    products: this.localStorageWishlist[this.merchantId].map(({ productId, projectId, quantity }) => ({
                        productId,
                        quantity,
                        projectId,
                    })),
                },
            },
        });
    }
};
__decorate([
    Inject(),
    __metadata("design:type", typeof (_a = typeof UISessionService !== "undefined" && UISessionService) === "function" ? _a : Object)
], WishlistSmart.prototype, "uiSessionService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_b = typeof AppGuardian !== "undefined" && AppGuardian) === "function" ? _b : Object)
], WishlistSmart.prototype, "guardian", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_c = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _c : Object)
], WishlistSmart.prototype, "apolloClient", void 0);
WishlistSmart = __decorate([
    Service(),
    __metadata("design:paramtypes", [typeof (_d = typeof ContainerInstance !== "undefined" && ContainerInstance) === "function" ? _d : Object])
], WishlistSmart);
export { WishlistSmart };
