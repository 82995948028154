import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import env from "@root/startup/env";
import React, { useState } from "react";
import { ProjectLogin } from "../components";
import { Button } from "../components/button";
import { NotFoundImage } from "../components/svg/images";
import styles from "./NotFound.module.scss";
export const NotFound = ({ context = "page-not-found", withLogin, onFinishLogin, goHome, }) => {
    const onReturnToHome = goHome
        ? goHome
        : () => (window.location.href = env.LANDING_APP_URL);
    const [projectLoginVisible, setProjectLoginVisible] = useState(false);
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx(NotFoundImage, {}, void 0), _jsx("h1", { children: "Houston we have a problem!" }, void 0), context === "product-not-found" && (_jsx("h2", { children: "The product you are looking for does not exist." }, void 0)), context === "page-not-found" && (_jsx("h2", { children: "The page you are looking for does not exist." }, void 0)), context === "project-not-found" && (_jsx("h2", { children: "The project you are looking for does not exist." }, void 0)), context === "shop-not-live" && (_jsx("h2", { children: "The shop you are looking for is not live, yet, or does not exist." }, void 0)), context === "project-not-live" && (_jsx("h2", { children: "The project you are looking for is not live, yet, or you do not have access to view it." }, void 0)), context === "world-not-found" && (_jsx("h2", { children: "The world you are looking for does not exist." }, void 0))] }), void 0), _jsx("button", Object.assign({ onClick: onReturnToHome, type: "button", className: styles.linkButton }, { children: "Return to Home" }), void 0), withLogin && (_jsxs("div", Object.assign({ style: { marginTop: "1rem" } }, { children: [_jsx(Button, Object.assign({ onClick: () => setProjectLoginVisible(true) }, { children: "Click to login" }), void 0), projectLoginVisible && (_jsx(ProjectLogin, { onClose: () => setProjectLoginVisible(false), onFinishLogin: onFinishLogin }, void 0))] }), void 0))] }), void 0));
};
