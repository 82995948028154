import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
// TODO: no more tawk for the moment :)
const CheckoutFooter = ({ onClick }) => {
    // const tawkMessengerRef = useRef<any>();
    // const onLoad = () => {
    //   if (!window.Tawk_API?.minimize) return;
    //   window.Tawk_API.minimize();
    // };
    const t = useTranslate("components.checkout.footer");
    return (_jsx("div", { className: "cc-checkout-footer" }, void 0));
};
export default CheckoutFooter;
