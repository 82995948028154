import { gql } from "@apollo/client";
export const CREATE_VR_AVATAR = gql `
  mutation UsersVRAvatarsCreate($input: UsersVRAvatarsCreateInput!) {
    UsersVRAvatarsCreate(input: $input) {
      file {
        _id
        name
        downloadUrl
      }
    }
  }
`;
