import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Form, Input } from "antd";
import { Button } from "@bundles/UIAppBundle/components/button";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
const CheckoutCreateAccountForm = ({ onRegister, onGoToLogin, }) => {
    const t = useTranslate('components.checkout.createAccount');
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "cc--text-bold cc-checkout-form__title" }, { children: t('createAccount') }), void 0), _jsxs(Form, Object.assign({ onFinish: onRegister }, { children: [_jsx("div", Object.assign({ className: "cc-checkout-form__row" }, { children: _jsxs("div", Object.assign({ className: "cc-row" }, { children: [_jsx(Form.Item, Object.assign({ label: false, name: "firstName", className: "cc-col-6" }, { children: _jsx(Input, { placeholder: t('fields.firstName.placeholder'), className: "cc-checkout-input" }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ label: false, name: "lastName", className: "cc-col-6" }, { children: _jsx(Input, { placeholder: t('fields.lastName.placeholder'), className: "cc-checkout-input" }, void 0) }), void 0)] }), void 0) }), void 0), _jsx(Form.Item, Object.assign({ label: false, name: "email", className: "cc-checkout-form__row" }, { children: _jsx(Input, { placeholder: t('fields.email.placeholder'), className: "cc-checkout-input" }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ label: false, name: "phoneNumber", className: "cc-checkout-form__row" }, { children: _jsx(Input, { placeholder: t('fields.phoneNumber.placeholder'), className: "cc-checkout-input" }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ label: false, name: "password", className: "cc-checkout-form__row" }, { children: _jsx(Input, { type: "password", placeholder: t('fields.password.placeholder'), className: "cc-checkout-input" }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "cc-checkout-form__text" }, { children: [t('alreadyHaveAccount'), " ", _jsx("a", Object.assign({ onClick: onGoToLogin, href: "#" }, { children: t('signIn') }), void 0)] }), void 0), _jsx(Button, Object.assign({ bgColor: "dark-gray", color: "white", type: "submit", className: "cc--full-width" }, { children: t('createAccount') }), void 0)] }), void 0)] }, void 0));
};
export default CheckoutCreateAccountForm;
