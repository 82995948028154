import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { newSmart } from "@bluelibs/smart";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { NotFound } from "@bundles/UIAppBundle/overrides";
import { GET_SHOP_INFO } from "@bundles/UIAppBundle/queries/getShopInfo.query";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { AcceptedLanguage } from "@root/api.types";
import { useState } from "react";
import { ShopInfoComponent } from "./shop-info";
import { ShopSmart } from "@bundles/UIAppBundle/smarts/Shop.smart";
import { ShopLayout } from "@bundles/UIAppBundle/components/shop-layout";
export const ShopPageDsgvoWrapper = ({ linkName }) => {
    const [_, BasketSmartProvider] = newSmart(BasketSmart);
    const [_2, ShopSmartProvider] = newSmart(ShopSmart);
    return (_jsx(BasketSmartProvider, { children: _jsx(ShopSmartProvider, { children: _jsx(ShopPageDsgvo, { linkName: linkName }, void 0) }, void 0) }, void 0));
};
export const ShopPageDsgvo = ({ linkName }) => {
    const uiSession = useUISession();
    const [shopInfo, setShopInfo] = useState(null);
    const [hasError, setHasError] = useState(false);
    const {} = useQuery(GET_SHOP_INFO(uiSession.state.currentLocale || AcceptedLanguage.en), {
        variables: {
            input: {
                linkName,
            },
        },
        onCompleted: (data) => {
            setShopInfo(data.EndUsersGetShopInfo);
        },
        onError: (err) => {
            setHasError(true);
        },
    });
    if (hasError) {
        return _jsx(NotFound, { context: "shop-not-live" }, void 0);
    }
    if (!shopInfo) {
        return _jsx(Loader, { centerInScreen: true }, void 0);
    }
    return (_jsx(ShopLayout, Object.assign({ navbar: {
            shopName: shopInfo.name,
            shopLinkName: linkName,
            isDsgvo: true,
        } }, { children: _jsx(ShopInfoComponent, { withoutHr: true, shopInfo: shopInfo }, void 0) }), void 0));
};
