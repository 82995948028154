import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ShopFooter } from "@bundles/UIAppBundle/pages/Shop/footer";
import { ShopNavbar, } from "@bundles/UIAppBundle/pages/Shop/navbar/ShopNavbar";
import styles from "./styles.module.scss";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { useEffect } from "react";
import classNames from "classnames";
import ReactHelmet from "react-helmet";
export const ShopLayout = ({ children, navbar, mainFullHeight }) => {
    const uiSession = useUISession();
    const isEmbedded = window !== window.top;
    useEffect(() => {
        uiSession.set("isEmbedded", isEmbedded, { persist: true });
    }, []);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs(ReactHelmet, { children: [_jsx("title", { children: "Regionalis.biz" }, void 0), _jsx("link", { rel: "icon", href: "/public/favicos/regionalis.png", type: "image/x-icon" }, void 0)] }, void 0), _jsx(ShopNavbar, Object.assign({}, navbar, { isEmbedded: isEmbedded }), void 0), _jsx("div", Object.assign({ className: classNames(styles.main, {
                    [styles["main-full-height"]]: mainFullHeight,
                }) }, { children: children }), void 0), isEmbedded ? null : (_jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(ShopFooter, {}, void 0) }), void 0))] }), void 0));
};
