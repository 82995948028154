import { I18NService, XRouter, XUIBundle } from "@bluelibs/x-ui";
import * as Routes from "./routes";
import { Bundle } from "@bluelibs/core";
import { AppGuardian } from "./services/AppGuardian";
import { i18n } from "./i18n";
import { translations } from "./i18n/translations";
import * as ComponentOverrides from "./overrides";
export class UIAppBundle extends Bundle {
    async init() {
        const xui = this.container.get(XUIBundle);
        xui.updateComponents(ComponentOverrides);
        xui.setGuardianClass(AppGuardian);
        xui.storeI18N(i18n);
        this.setupI18N();
        // All routes are added via the routing service
        const router = this.container.get(XRouter);
        router.add(Routes);
        const langRoutes = Object.keys(Routes).reduce((acc, key) => {
            return {
                ...acc,
                [`${key}.lang`]: {
                    ...Routes[key],
                    name: `${key}.lang`,
                    path: "/:lang" + Routes[key].path,
                },
            };
        }, {});
        router.add(langRoutes);
    }
    setupI18N() {
        const i18nService = this.container.get(I18NService);
        for (const language in translations) {
            const trans = translations[language];
            for (const messages of trans) {
                i18nService.extend(language, messages);
            }
        }
    }
}
