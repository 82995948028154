import { gql } from "@apollo/client";
import { AcceptedLanguage } from "@root/api.types";
export const GET_DETAILS_ABOUT_ITEMS_IN_WISHLIST = (lang = AcceptedLanguage.en) => gql `
  query ($input: EndUsersGetDetailsAboutItemsInWishlistInput!) {
    EndUsersGetDetailsAboutItemsInWishlist(input: $input) {
      product {
        _id

        name {
          text(lang: ${lang})
        }

        description {
          text(lang: ${lang})
        }

        media {
          image2dFiles {
            downloadUrl
          }
          image360Files {
            downloadUrl
          }
        }
      }

      quantity
    }
  }
`;
