export const linksLeft = [
    {
        href: "https://www.regionalis.biz/fuer-produzenten",
        title: "Für Produzenten",
    },
    {
        href: "https://www.regionalis.biz/bezahlung-versand-1",
        title: "Bezahlung & Versand",
    },
    {
        href: "https://www.regionalis.biz/faqs-kufer",
        title: "FAQs Käufer",
    },
    {
        href: "https://www.regionalis.biz/faqs-verkaufer",
        title: "FAQs Verkäufer",
    },
    {
        href: "https://www.regionalis.biz/verkuferrichtlinen",
        title: "Verkäuferrichtlinien",
    },
    {
        href: "https://www.regionalis.biz/agb",
        title: "AGB",
    },
];
export const linksRight = [
    {
        href: "https://www.regionalis.biz/blog-2-2",
        title: "Blog",
    },
    {
        href: "https://www.regionalis.biz/uber-uns",
        title: "Über uns",
    },
    {
        href: "https://www.regionalis.biz/impressum-kontakt",
        title: "Impressum & Kontakt",
    },
    {
        href: "https://www.regionalis.biz/datenschutzerklarung",
        title: "Datenschutzerklärung",
    },
    {
        href: "https://www.regionalis.biz/widerrufsformular",
        title: "Widerrufsformular",
    },
];
