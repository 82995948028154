import { Logout as LogoutComponent } from "./Logout";
import { Login as LoginComponent } from "./Login";
import OAuth from "./oauth";
export const Logout = {
    path: "/logout",
    component: LogoutComponent,
};
export const Login = {
    path: "/login",
    component: LoginComponent,
};
export const OAuthRoute = {
    path: "/oauth",
    component: OAuth,
};
