import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useQuery } from "@apollo/client";
import { newSmart } from "@bluelibs/smart";
import { SHOP_GET_DISTRICTS } from "@bundles/UIAppBundle/queries/shopGetCountries.query";
import { SHOP_GET_PRODUCT_CATEGORIES } from "@bundles/UIAppBundle/queries/shopGetProductCategories.query";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { AcceptedLanguage, } from "@root/api.types";
import { useEffect, useState } from "react";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { SHOP_GET_ALL_PRODUCTS } from "@bundles/UIAppBundle/queries/shopGetAllProducts.query";
import { ShopProductsComponent } from "./shopProducts";
import { ShopSidebarProvider, useShopSidebarContext } from "./sidebar";
import { ShopSmart } from "@bundles/UIAppBundle/smarts/Shop.smart";
import { SHOP_GET_TAGS } from "@bundles/UIAppBundle/queries/shopsGetTags.query";
import { ShopLayout } from "@bundles/UIAppBundle/components/shop-layout";
export const ShopsProductsPageWraper = () => {
    const [_, BasketSmartProvider] = newSmart(BasketSmart);
    const [_2, ShopSmartProvider] = newSmart(ShopSmart);
    return (_jsx(ShopSidebarProvider, { children: _jsx(BasketSmartProvider, { children: _jsx(ShopSmartProvider, { children: _jsx(ShopsProductsPage, {}, void 0) }, void 0) }, void 0) }, void 0));
};
const ShopsProductsPage = () => {
    const [districts, setDistricts] = useState();
    const [productCategories, setProductCategories] = useState();
    const [tags, setTags] = useState(null);
    const [products, setProducts] = useState();
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [limit, setLimit] = useState(50);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const { filters } = useShopSidebarContext();
    const uiSession = useUISession();
    const lang = uiSession.get("currentLocale", AcceptedLanguage.en);
    const { loading: loadingDistricts } = useQuery(SHOP_GET_DISTRICTS, {
        onCompleted: (data) => {
            setDistricts(data.EndUsersShopGetDistricts);
        },
    });
    const {} = useQuery(SHOP_GET_TAGS, {
        variables: {
            input: {},
        },
        onCompleted: (data) => {
            setTags(data.EndUsersShopGetTags);
        },
    });
    const { loading: loadingProductCategories } = useQuery(SHOP_GET_PRODUCT_CATEGORIES, {
        variables: {
            input: {},
        },
        onCompleted: (data) => {
            setProductCategories(data.EndUsersShopGetProductCategories);
        },
    });
    const [getProducts, { loading: loadingProducts }] = useLazyQuery(SHOP_GET_ALL_PRODUCTS(lang), {
        fetchPolicy: "network-only",
    });
    const fetchMoreProducts = async () => {
        setIsFetchingMore(true);
        await getProducts({
            variables: {
                input: {
                    skip,
                    limit,
                    filters: {
                        district: filters === null || filters === void 0 ? void 0 : filters.bundesland,
                        category: filters === null || filters === void 0 ? void 0 : filters.category,
                        text: filters === null || filters === void 0 ? void 0 : filters.text,
                        tags: filters === null || filters === void 0 ? void 0 : filters.tags,
                    },
                },
            },
            onCompleted: (data) => {
                const prods = data.EndUsersShopsGetAllProducts;
                setProducts((prev) => (prev || []).concat(prods));
                setSkip((prev) => prev + limit);
                setHasMore(prods.length === limit);
            },
        });
        setIsFetchingMore(false);
    };
    const fetchProductsOnFiltersChange = async () => {
        setSkip(0);
        setHasMore(true);
        await getProducts({
            variables: {
                input: {
                    skip: 0,
                    limit,
                    filters: {
                        district: filters === null || filters === void 0 ? void 0 : filters.bundesland,
                        category: filters === null || filters === void 0 ? void 0 : filters.category,
                        text: filters === null || filters === void 0 ? void 0 : filters.text,
                        tags: filters === null || filters === void 0 ? void 0 : filters.tags,
                    },
                },
            },
            onCompleted: (data) => {
                const prods = data.EndUsersShopsGetAllProducts;
                setProducts(prods);
                setSkip(limit);
                setHasMore(prods.length === limit);
            },
        });
    };
    useEffect(() => {
        fetchMoreProducts();
    }, []);
    useEffect(() => {
        if (filters) {
            fetchProductsOnFiltersChange();
        }
    }, [filters]);
    if (!districts || !productCategories || !products || !tags) {
        return (_jsx(ShopLayout, Object.assign({ mainFullHeight: true }, { children: _jsx(Loader, { centerInScreen: true }, void 0) }), void 0));
    }
    return (_jsx(ShopLayout, { children: _jsx(ShopProductsComponent, { isLoadingProducts: loadingProducts && !isFetchingMore, productCategories: productCategories, districts: districts, fetchMoreProducts: fetchMoreProducts, hasMore: hasMore, products: products, tags: tags }, void 0) }, void 0));
};
