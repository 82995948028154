import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { useAppGuardian } from "@bundles/UIAppBundle/services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StringParam, useQueryParams } from "use-query-params";
const OAuth = () => {
    var _a;
    const t = useTranslate("components.projectLogin");
    const tErrors = useTranslate("errors");
    const router = useRouter();
    const guardian = useAppGuardian();
    const [{ token, errCode, next }] = useQueryParams({
        token: StringParam,
        errCode: StringParam,
        next: StringParam,
    });
    const [handled, setHandled] = useState(false);
    const redirectRoute = { path: (_a = new URL(next)) === null || _a === void 0 ? void 0 : _a.pathname };
    const login = async (token) => {
        await guardian.reissueToken(token);
        router.go(redirectRoute);
        toast.info(`${t("welcome")}, ${guardian.state.user.fullName}!`);
    };
    const handleError = async (err) => {
        router.go(redirectRoute);
        toast.error(tErrors(err));
    };
    useEffect(() => {
        if (handled)
            return;
        if (!errCode && !token) {
            router.go(redirectRoute);
            setHandled(true);
            return;
        }
        if (errCode) {
            handleError(errCode);
        }
        else if (token) {
            login(token);
        }
        setHandled(true);
    }, [token, errCode]);
    return _jsx(Loader, {}, void 0);
};
export default OAuth;
