import { gql } from "@apollo/client";
export const GET_VR_AVATAR = gql `
  query UsersVRAvatarsGet {
    UsersVRAvatarsGet {
      file {
        _id
        name
        downloadUrl
      }
    }
  }
`;
