var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Inject, Service } from "@bluelibs/core";
import { EJSON } from "@bluelibs/ejson";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { Smart, UISessionService } from "@bluelibs/x-ui";
import { EndUsersJoinChatEventType, } from "@root/api.types";
import { createContext } from "react";
import { CREATE_CHAT_SESSION, JOIN_CHAT } from "../mutations";
import { SEND_MESSAGE } from "../mutations/SendMessage.mutation";
import { GET_ALL_MESSAGES } from "../queries/PublicChatGetAllMessages.query";
export const ChatSmartContext = createContext(null);
let ChatSmart = class ChatSmart extends Smart {
    constructor() {
        super();
        this.setState({
            connectedToChat: false,
            isConnecting: false,
            messages: [],
        });
    }
    static getContext() {
        return ChatSmartContext;
    }
    async connect(projectId, firstName, lastName) {
        this.updateState({ isConnecting: true });
        let token;
        const chatTokenFromSession = this.uiSessionService.state.chatTokenByProjectId[this.uiSessionService.state.projectId];
        if (chatTokenFromSession) {
            token = chatTokenFromSession;
        }
        else {
            const { data } = await this.apolloClient.mutate({
                mutation: CREATE_CHAT_SESSION,
                variables: {
                    input: {
                        projectId,
                        firstName,
                        lastName,
                    },
                },
            });
            token = data.EndUsersCreateChatSession.token;
            this.uiSessionService.set("chatTokenByProjectId", {
                ...this.uiSessionService.state.chatTokenByProjectId,
                [this.uiSessionService.state.projectId]: token,
            }, { persist: true });
        }
        const messagesQueryResponse = await this.apolloClient.query({
            query: GET_ALL_MESSAGES,
            variables: {
                input: {
                    token,
                },
            },
        });
        this.updateState({
            messages: messagesQueryResponse.data.PublicChatGetAllMessages,
        });
        const observable = this.apolloClient.subscribe({
            query: JOIN_CHAT,
            variables: {
                input: {
                    token,
                },
            },
        });
        observable.subscribe({
            error(err) {
                console.log(err);
            },
            next: (value) => {
                const payload = value.data.EndUsersJoinChat;
                const { event } = payload;
                if (event === EndUsersJoinChatEventType.MESSAGE_SENT) {
                    const { message } = EJSON.parse(payload.data);
                    const messages = [...this.state.messages];
                    messages.push(message);
                    this.updateState({
                        messages,
                    });
                }
                if (event === EndUsersJoinChatEventType.MESSAGE_DELETED) {
                    const { messageId } = EJSON.parse(payload.data);
                    const messages = this.state.messages.filter((m) => !messageId.equals(m._id));
                    this.updateState({
                        messages,
                    });
                }
            },
        });
        this.updateState({ isConnecting: false, connectedToChat: true, token });
    }
    async sendMessage(text) {
        await this.apolloClient.mutate({
            mutation: SEND_MESSAGE,
            variables: {
                input: {
                    text,
                    token: this.state.token,
                },
            },
        });
    }
};
__decorate([
    Inject(),
    __metadata("design:type", typeof (_a = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _a : Object)
], ChatSmart.prototype, "apolloClient", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_b = typeof UISessionService !== "undefined" && UISessionService) === "function" ? _b : Object)
], ChatSmart.prototype, "uiSessionService", void 0);
ChatSmart = __decorate([
    Service(),
    __metadata("design:paramtypes", [])
], ChatSmart);
export { ChatSmart };
