import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AssetMediaRenderType, AssetMediaTypeFor3D, } from "@root/api.types";
import { EMPTY_STRING_FOR_STATIC_MARKUP_RENDER } from "../pages/Project/constants";
const HighlightComponent = (props) => (_jsx("a-entity", Object.assign({}, props, { "obj-model": `obj: #place-button-obj; mtl: #place-button-mtl;`, scale: "0.01 0.01 0.01", rotation: "0 90 0" }), void 0));
export const AssetEntities = ({ project, isForWorld = false, assetService, projectSmart, }) => {
    var _a;
    const { state } = projectSmart;
    const { billing } = project;
    const shouldBeHighlighted = ((_a = AFRAME.scenes[0]) === null || _a === void 0 ? void 0 : _a.hasLoaded) && state.isProductSelectedFromMerchants;
    return (_jsxs(_Fragment, { children: [_jsx("a-gltf-model", { visible: !state.isWatching360, src: "#world" }, void 0), project.world.meshFile ? (_jsx("a-gltf-model", { src: "#nav-mesh", "nav-mesh": EMPTY_STRING_FOR_STATIC_MARKUP_RENDER, class: "navmesh", visible: false }, void 0)) : null, project.assetInfos.map((assetInfo) => {
                var _a, _b, _c;
                const hasProductPlaced = Boolean(assetInfo.product);
                if (!isForWorld && !projectSmart.shouldShowAsset(assetInfo))
                    return null;
                const id = (_a = assetInfo._id) !== null && _a !== void 0 ? _a : assetInfo.assetPosition._id;
                // combination of projectAssetInfoId and downloadUrl make the itmes unique
                const deduceKey = (mediaFile) => {
                    let res = id.toString();
                    if (Array.isArray(mediaFile)) {
                        res += mediaFile.reduce((prev, file) => prev + file.downloadUrl, "");
                    }
                    else {
                        res += mediaFile.downloadUrl;
                    }
                    return res;
                };
                const assetPosition = assetInfo.assetPosition;
                const mediaType = hasProductPlaced
                    ? assetInfo.mediaType
                    : assetInfo.assetPosition.mediaType;
                const typeFor3D = hasProductPlaced
                    ? assetInfo.typeFor3D
                    : assetInfo.assetPosition.typeFor3D;
                const media = hasProductPlaced
                    ? assetInfo.product.media
                    : assetInfo.assetPosition.media;
                const { position, scale, rotation } = hasProductPlaced
                    ? assetService.calculatePositionWithDelta(assetPosition.coordinates, assetInfo.deltaCoordinates)
                    : assetPosition.coordinates;
                if (mediaType === AssetMediaRenderType.IMAGE_2D) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, position: position, visible: !state.isWatching360, 
                        // outline
                        scale: scale, class: "interactable", rotation: rotation }, { children: [_jsx("a-image", { "fit-texture": true, id: `${id}-image`, src: `#${id}` }, void 0), shouldBeHighlighted && _jsx(HighlightComponent, {}, void 0)] }), deduceKey(media.image2dFiles[0])));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_360) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, visible: !state.isWatching360, position: position, scale: scale, class: "interactable", rotation: rotation }, { children: [_jsx("a-image", { "fit-texture": true, id: `${id}-image`, src: `#${id}` }, void 0), shouldBeHighlighted && _jsx(HighlightComponent, {}, void 0)] }), deduceKey(media.image360Files[0])));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_360_CAROUSEL) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, visible: !state.isWatching360, position: position, scale: scale, rotation: rotation, class: "interactable" }, { children: [shouldBeHighlighted && _jsx(HighlightComponent, {}, void 0), _jsx("a-image", { "carousel-2d": `index: 0`, id: `${id}-image`, src: `#${id}-0` }, void 0)] }), deduceKey(media.image360Files)));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_2D_CAROUSEL) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, visible: !state.isWatching360, position: position, scale: scale, rotation: rotation, class: "interactable" }, { children: [shouldBeHighlighted && _jsx(HighlightComponent, {}, void 0), _jsx("a-image", { "fit-texture": true, "carousel-2d": `index: 0`, id: `${id}-image`, src: `#${id}-0` }, void 0)] }), deduceKey(media.image2dFiles)));
                }
                if (mediaType === AssetMediaRenderType.ITEM_3D) {
                    if (typeFor3D === AssetMediaTypeFor3D.OBJ_MTL) {
                        return (_jsx("a-entity", Object.assign({ id: `${id}-entity`, visible: !state.isWatching360, class: "interactable", "obj-model": `obj: #${id}-obj; mtl: #${id}-mtl`, position: position, scale: scale, rotation: rotation }, { children: shouldBeHighlighted && _jsx(HighlightComponent, {}, void 0) }), deduceKey({
                            downloadUrl: media.image3dOBJ.downloadUrl + media.image3dMTL.downloadUrl,
                        })));
                    }
                    if (typeFor3D === AssetMediaTypeFor3D.GLB) {
                        return (_jsx("a-entity", Object.assign({ id: `${id}-entity`, visible: !state.isWatching360, class: "interactable", "gltf-model": `url(${media.image3DGLB.downloadUrl})`, position: position, scale: scale, rotation: rotation }, { children: shouldBeHighlighted && _jsx(HighlightComponent, {}, void 0) }), deduceKey(media.image3DGLB)));
                    }
                }
                if (mediaType === AssetMediaRenderType.VIDEO) {
                    const mediaUrl = media.videoUrl || ((_b = media.videoUpload) === null || _b === void 0 ? void 0 : _b.downloadUrl);
                    if (!mediaUrl) {
                        return;
                    }
                    const isVideoUrlDownloadable = (url) => {
                        try {
                            const objUrl = new URL(url);
                            const undownloadableUrls = ["youtube.com", "vimeo.com"];
                            return undownloadableUrls.some((u) => objUrl.hostname.includes(u));
                        }
                        catch {
                            return false;
                        }
                    };
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, position: position, visible: !state.isWatching360, 
                        // outline
                        scale: scale, class: "interactable", rotation: rotation }, { children: [_jsx("video", { id: `${id}-video`, src: mediaUrl, autoPlay: false, crossOrigin: "anonymous", loop: true }, void 0), (media.videoUrl && isVideoUrlDownloadable(mediaUrl)) ||
                                (((_c = media.videoUpload) === null || _c === void 0 ? void 0 : _c.downloadUrl) && (_jsx("a-video", { src: `#${id}-video` }, void 0))), shouldBeHighlighted && _jsx(HighlightComponent, {}, void 0)] }), deduceKey({ downloadUrl: mediaUrl })));
                }
            })] }, void 0));
};
