import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { uniqueId } from "lodash-es";
const Checkbox = React.forwardRef(({ className, id = uniqueId("checkbox_"), isInvalid = false, children, ...props }, ref) => {
    const checkboxClasses = classNames("cc-checkbox", {
        "cc--is-invalid": isInvalid,
    }, className);
    return (_jsxs("div", Object.assign({ className: checkboxClasses }, { children: [_jsx("input", Object.assign({ ref: ref, id: id, type: "checkbox" }, props), void 0), _jsx("label", Object.assign({ htmlFor: id }, { children: children }), void 0)] }), void 0));
});
export default Checkbox;
