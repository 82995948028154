var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Inject, Service } from "@bluelibs/core";
import { Smart } from "@bluelibs/smart";
import autoBind from "auto-bind";
import { createContext } from "react";
import { SHOP_PRODUCTS_FIND_ONE } from "../queries/shopProductsFindOne.query";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { UISessionService } from "@bluelibs/x-ui-session-bundle";
import { AcceptedLanguage } from "@root/api.types";
import env from "@root/startup/env";
export const ShopSmartContext = createContext(null);
let ShopSmart = class ShopSmart extends Smart {
    constructor() {
        super();
        this.init();
        this.setState({
            isEmbedded: false,
        });
        this.iframeDomain = env.REGIONALIS_APP_URL;
        autoBind(this);
    }
    static getContext() {
        return ShopSmartContext;
    }
    sendMessageToParent(data) {
        parent.postMessage(data, this.iframeDomain);
    }
    async getProduct(productSlug, shopSlug) {
        const { data, error } = await this.apolloClient.query({
            query: SHOP_PRODUCTS_FIND_ONE(this.uiSessionService.state.currentLocale || AcceptedLanguage.en),
            fetchPolicy: "network-only",
            variables: {
                input: {
                    productSlug,
                    shopSlug,
                },
            },
        });
        return data === null || data === void 0 ? void 0 : data.EndUsersGetShopProduct;
    }
};
__decorate([
    Inject(),
    __metadata("design:type", typeof (_a = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _a : Object)
], ShopSmart.prototype, "apolloClient", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_b = typeof UISessionService !== "undefined" && UISessionService) === "function" ? _b : Object)
], ShopSmart.prototype, "uiSessionService", void 0);
ShopSmart = __decorate([
    Service(),
    __metadata("design:paramtypes", [])
], ShopSmart);
export { ShopSmart };
