import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ShopProductDetail } from "./productDetail";
import { useEffect, useState } from "react";
import { newSmart, useSmart } from "@bluelibs/smart";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { ShopSmart } from "@bundles/UIAppBundle/smarts/Shop.smart";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { NotFound } from "@bundles/UIAppBundle/overrides";
import { ShopLayout } from "@bundles/UIAppBundle/components/shop-layout";
const ShopProductPage = ({ productSlug, shopSlug, }) => {
    const [product, setProduct] = useState();
    const [error, setError] = useState();
    const shopSmart = useSmart(ShopSmart);
    useEffect(() => {
        shopSmart
            .getProduct(productSlug, shopSlug)
            .then((product) => {
            setProduct(product);
        })
            .catch((err) => {
            setError(err);
        });
    }, []);
    const goHome = () => {
        window.location.href = "/shops/products";
    };
    if (error) {
        return _jsx(NotFound, { context: "product-not-found", goHome: goHome }, void 0);
    }
    if (!product) {
        return (_jsxs(ShopLayout, Object.assign({ mainFullHeight: true }, { children: [_jsx(Loader, { centerInScreen: true }, void 0), ";"] }), void 0));
    }
    return (_jsx(ShopLayout, Object.assign({ navbar: {
            shopLinkName: product.shopInfo.linkName,
            shopName: product.shopInfo.name,
            productName: product.name.text,
            productSlug,
        } }, { children: _jsx(ShopProductDetail, { shopInfo: product.shopInfo, product: product }, void 0) }), void 0));
};
export const ShopProductPageWrapper = ({ productSlug, shopSlug, }) => {
    const [_, BasketSmartProvider] = newSmart(BasketSmart);
    const [_2, ShopSmartProvider] = newSmart(ShopSmart);
    return (_jsx(BasketSmartProvider, { children: _jsx(ShopSmartProvider, { children: _jsx(ShopProductPage, Object.assign({}, { productSlug, shopSlug }), void 0) }, void 0) }, void 0));
};
