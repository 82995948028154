export const links = [
    {
        title: "Shop",
        href: "/shops/products",
        isLink: true,
    },
    {
        title: "Blog",
        href: "https://www.regionalis.biz/blog",
    },
    {
        title: "Über uns",
        href: "https://www.regionalis.biz/uber-uns",
    },
    {
        title: "Unsere-Produzenten",
        href: "/shops",
        isLink: true,
    },
    {
        title: "Kunsthandwerksmärkte",
        href: "https://www.regionalis.biz/kunsthandwerksmaerkte",
    },
];
