import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { IconArrowLeft } from "@bundles/UIAppBundle/components/svg";
export const Breadcrumbs = ({ productName, shopName, shopLinkName, isDsgvo, isEmbedded }) => {
    const isMobile = window.innerWidth < 768;
    const uiSession = useUISession();
    const lang = uiSession.get("currentLocale");
    return (_jsxs("div", Object.assign({ className: styles.breadcrumbs }, { children: [isEmbedded ? null : (_jsxs(Fragment, { children: [_jsx(Link, Object.assign({ to: `/${lang}/shops/products` }, { children: "Products" }), void 0), "\u00A0/\u00A0", _jsx(Link, Object.assign({ to: `/${lang}/shops` }, { children: "Shops" }), void 0)] }, void 0)), isEmbedded && productName && (_jsxs(Link, Object.assign({ className: styles.back, to: `/${lang}/shops/products` }, { children: [_jsx(IconArrowLeft, {}, void 0), " ", _jsx("span", { children: "Back" }, void 0)] }), void 0)), shopName && shopLinkName && !isEmbedded && (_jsxs(Fragment, { children: [_jsx("div", { className: styles.separator }, void 0), productName || isDsgvo ? (_jsx(Link, Object.assign({ to: `/${lang}/${shopLinkName}` }, { children: shopName }), void 0)) : (_jsx("span", { children: shopName }, void 0))] }, void 0)), !isMobile && (_jsxs(Fragment, { children: [isDsgvo && (_jsxs(Fragment, { children: [_jsx("div", { className: styles.separator }, void 0), _jsx("span", { children: "DSGVO" }, void 0)] }, void 0)), productName && !isEmbedded && (_jsxs(Fragment, { children: [_jsx("div", { className: styles.separator }, void 0), _jsx("div", Object.assign({ className: styles.link }, { children: _jsx("span", { children: productName }, void 0) }), void 0)] }, void 0))] }, void 0))] }), void 0));
};
