import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from "react";
import { uniqueId } from "lodash-es";
import classNames from "classnames";
import { useOnClickOutside } from "@bundles/UIAppBundle/hooks";
const Dropdown = ({ headerButton, items, position = "top", }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setIsOpen(false));
    const handleClick = () => setIsOpen((prevIsOpen) => !prevIsOpen);
    const dropdownClasses = classNames("cc-dropdown", `cc--${position}`);
    const renderHeaderButton = () => {
        if (typeof headerButton === "function") {
            return headerButton({ isOpen });
        }
        return headerButton;
    };
    return (_jsxs("div", Object.assign({ className: dropdownClasses, ref: ref }, { children: [_jsx("div", Object.assign({ className: "cc-dropdown-button", onClick: handleClick }, { children: renderHeaderButton() }), void 0), isOpen && (_jsx("div", Object.assign({ className: "cc-dropdown-wrapper" }, { children: _jsx("ul", Object.assign({ className: "cc-custom-list cc-dropdown-menu" }, { children: items.map(({ label, url, icon, onClick, Component }) => {
                        const Icon = icon;
                        const newOnClick = () => {
                            onClick();
                            handleClick();
                        };
                        return (_jsx("li", Object.assign({ className: "cc-dropdown-item" }, { children: _jsx("a", Object.assign({ href: url, onClick: newOnClick }, { children: _jsxs("span", { children: [Component && _jsx(Component, {}, void 0), icon && _jsx(Icon, { className: "cc-dropdown-item__icon" }, void 0), label && label] }, void 0) }), void 0) }), uniqueId("dropdown-item-")));
                    }) }), void 0) }), void 0))] }), void 0));
};
export default Dropdown;
