import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, use, useRouter, useSmart, useTranslate, useUISession, } from "@bluelibs/x-ui";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { PaymentMethod, } from "@root/api.types";
import { useMutation } from "@apollo/client";
import { CREATE_SHOP_CHECKOUT_SESSION } from "@bundles/UIAppBundle/mutations/EndUsersShopCreateCheckoutSession.mutation";
import { toast } from "react-toastify";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { StringParam, useQueryParams } from "use-query-params";
import { Link } from "react-router-dom";
import { ShopSmart } from "@bundles/UIAppBundle/smarts/Shop.smart";
import { RouterService } from "@bundles/UIAppBundle/services/Router";
import { extractError } from "@bundles/UIAppBundle/utils/extractErrors";
import classNames from "classnames";
import { CreditCard, Eps, Klarna, Paypal, Sepa, } from "@bundles/UIAppBundle/components/svg";
import { ShopLayout } from "@bundles/UIAppBundle/components/shop-layout";
import { Routes } from "@bundles/UIAppBundle";
export const BasketPageWrapper = () => {
    const [_, BasketSmartProvider] = newSmart(BasketSmart);
    const [_2, ShopSmartProvider] = newSmart(ShopSmart);
    return (_jsx(BasketSmartProvider, { children: _jsx(ShopSmartProvider, { children: _jsx(BasketPage, {}, void 0) }, void 0) }, void 0));
};
export const BasketPage = () => {
    const t = useTranslate("pages.basket");
    const tErrors = useTranslate("errors");
    const uiSession = useUISession();
    const basketSmart = useSmart(BasketSmart);
    const shopSmart = useSmart(ShopSmart);
    const lang = uiSession.get("currentLocale");
    const lastShopLinkName = uiSession.get("lastShopLinkName");
    const isEmbedded = window.top !== window;
    const [needsToRefreshPage, setNeedsToRefreshPage] = useState(false);
    const [lastCheckedOutForItem, setLastCheckedOutForItem] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [selectedCoupons, setSelectedCoupons] = useState({});
    const [selectedMerchantId, setSelectedMerchantId] = useState(null);
    const router = useRouter();
    const [email, setEmail] = useState("");
    const { state: { basket }, updateBasket, removeFromBasket, changeQuantity, } = basketSmart;
    const embeddedInfo = uiSession.get("embeddedInfo");
    const routerService = use(RouterService);
    const [queryParams, setQueryParams] = useQueryParams({
        success: StringParam,
        cancel: StringParam,
        merchantId: StringParam,
    });
    const [isFromCheckout] = useState(() => {
        return queryParams.success;
    });
    const [createCheckoutSession, createCheckoutSessionState] = useMutation(CREATE_SHOP_CHECKOUT_SESSION);
    useEffect(() => {
        if (!basketSmart.state.initialised || isFromCheckout) {
            return;
        }
        updateBasket().then(() => {
            basketSmart.updateState({
                loaded: true,
            });
        });
    }, [basketSmart.state.initialised]);
    useEffect(() => {
        if (queryParams.success) {
            toast.success(t("success"));
        }
        if (queryParams.cancel) {
            toast.error(t("cancel"));
        }
        setQueryParams({
            success: undefined,
            cancel: undefined,
        });
    }, [queryParams.cancel, queryParams.success]);
    useEffect(() => {
        if (!queryParams.merchantId) {
            return;
        }
        if (!basketSmart.state.initialised) {
            return;
        }
        basketSmart.updateAfterCheckout(queryParams.merchantId).then(() => {
            basketSmart.updateState({
                loaded: true,
            });
            router.go(Routes.SHOPS_PRODUCTS);
        });
        setQueryParams({
            merchantId: undefined,
        });
    }, [queryParams.merchantId, basketSmart.state.initialised]);
    useEffect(() => {
        if (!basket.length) {
            return;
        }
        for (const item of basket) {
            if (item.shippingOptions.length === 1) {
                setSelectedOptions((prev) => ({
                    ...prev,
                    [item.merchantId]: item.shippingOptions[0].id,
                }));
            }
        }
        setSelectedMerchantId(basket[0].merchantId);
    }, [basket.length]);
    const handleQuantityChange = (productId, variantId, quantity) => {
        changeQuantity({
            productId,
            variantId,
            quantity,
        });
    };
    const handleOptionChange = (merchantId, optionId) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [merchantId]: optionId,
        }));
    };
    const handleCouponChange = (merchantId, coupon) => {
        setSelectedCoupons((prev) => ({
            ...prev,
            [merchantId]: coupon,
        }));
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handleRemove = async (productId, variantId) => {
        const hasDeletedLastProduct = await removeFromBasket({
            productId,
            variantId,
        });
        if (hasDeletedLastProduct) {
            setSelectedMerchantId(basket[0].merchantId);
        }
    };
    const onCheckout = async (item) => {
        var _a;
        if (!selectedOptions[item.merchantId]) {
            toast.info(t("selectShippingOption"));
            return;
        }
        let returnUrl, cancelUrl;
        if (shopSmart.state.isEmbedded) {
            returnUrl = routerService.build(embeddedInfo.returnUrl, {
                merchantId: item.merchantId,
            });
            cancelUrl = embeddedInfo.cancelUrl;
        }
        else {
            const url = window.location.href;
            const isEmbedded = window !== window.top;
            const urlObject = new URL(url);
            const base = `${urlObject.protocol}//${urlObject.hostname}${urlObject.port ? `:${urlObject.port}` : ""}`;
            if (isEmbedded) {
                returnUrl = `${base}/checkout?success=true&merchantId=${item.merchantId}`;
                cancelUrl = `${base}/checkout?cancel=true`;
            }
            else {
                returnUrl = `${base}/basket?success=true&merchantId=${item.merchantId}`;
                cancelUrl = `${base}/basket?cancel=true`;
            }
        }
        try {
            const { data } = await createCheckoutSession({
                variables: {
                    input: {
                        products: item.products.map((product) => ({
                            id: product.id,
                            variants: product.variants.map((variant) => ({
                                id: variant.details.id,
                                quantity: variant.quantity,
                            })),
                        })),
                        returnUrl,
                        cancelUrl,
                        email: Boolean(email) ? email : undefined,
                        shippingOptionId: selectedOptions[item.merchantId],
                        coupon: selectedCoupons[item.merchantId],
                    },
                },
            });
            const url = data.EndUsersShopCreateCheckoutSession;
            if (shopSmart.state.isEmbedded) {
                shopSmart.sendMessageToParent({
                    type: "checkout",
                    url,
                });
                return;
            }
            if (window === window.top) {
                window.location.href = url;
            }
            else {
                setLastCheckedOutForItem(item);
                setNeedsToRefreshPage(true);
                (_a = window.open(url, "_blank")) === null || _a === void 0 ? void 0 : _a.focus();
            }
        }
        catch (err) {
            const error = extractError(err);
            toast.error(tErrors(error.code, error.context));
        }
    };
    useEffect(() => {
        const listener = async (ev) => {
            if (ev.key !== "checkout" || !isEmbedded) {
                return;
            }
            setNeedsToRefreshPage(false);
            const key = ev.key;
            const value = ev.newValue;
            localStorage.removeItem(key);
            const data = JSON.parse(value);
            if (data.success) {
                toast.success(t("success"));
                await basketSmart.updateAfterCheckout(data.merchantId);
                router.go(Routes.SHOP, {
                    params: {
                        linkName: lastCheckedOutForItem.linkName,
                    },
                });
            }
            else {
                toast.info(t("cancel"));
            }
        };
        window.addEventListener("storage", listener);
        return () => {
            window.removeEventListener("storage", listener);
        };
    }, []);
    const currentBasketItem = basket.find((item) => item.merchantId === selectedMerchantId);
    if (!basketSmart.state.loaded) {
        return (_jsx(ShopLayout, Object.assign({ mainFullHeight: true }, { children: _jsx("div", Object.assign({ className: styles["basket-page"] }, { children: _jsx(Loader, { centerInScreen: true }, void 0) }), void 0) }), void 0));
    }
    return (_jsx(ShopLayout, { children: _jsxs("div", Object.assign({ className: styles["basket-page"] }, { children: [createCheckoutSessionState.loading && (_jsx("div", Object.assign({ style: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                    } }, { children: _jsx("div", Object.assign({ style: {
                            position: "relative",
                        } }, { children: _jsx(Loader, {}, void 0) }), void 0) }), void 0)), needsToRefreshPage && (_jsx("div", Object.assign({ className: styles["popup-overlay"] }, { children: _jsxs("div", Object.assign({ className: styles["popup-container"] }, { children: [_jsx("h4", { children: t("popupTitle") }, void 0), _jsx("p", { children: t("popupMessage") }, void 0)] }), void 0) }), void 0)), _jsxs("div", Object.assign({ className: styles["basket-container"] }, { children: [basket.length === 0 && (_jsx("div", Object.assign({ className: styles["empty-basket"] }, { children: _jsx("h4", { children: t("empty") }, void 0) }), void 0)), basket.length <= 1 ? null : (_jsxs("div", Object.assign({ className: styles["basket-shops-container"] }, { children: [_jsx("h3", Object.assign({ className: styles.instructionText }, { children: t("selectShop") }), void 0), _jsx("div", Object.assign({ className: styles.basketShops }, { children: basket.map((item) => (_jsx("div", Object.assign({ className: classNames(styles.basketShop, {
                                            [styles.basketShopSelected]: item.merchantId === selectedMerchantId,
                                        }), onClick: () => setSelectedMerchantId(item.merchantId) }, { children: _jsx("h4", { children: item.shopName }, void 0) }), item.merchantId))) }), void 0)] }), void 0)), currentBasketItem == null ? null : (_jsxs("div", Object.assign({ className: styles["purchase-section"] }, { children: [_jsx("div", Object.assign({ className: styles["purchase-section-products"] }, { children: currentBasketItem.products.map((product) => (_jsxs("div", Object.assign({ className: styles["product"] }, { children: [_jsx("img", { src: product.imageUrl, alt: product.name.text }, void 0), _jsxs("div", Object.assign({ className: styles["product-details"] }, { children: [_jsx("h4", { children: _jsx(Link, Object.assign({ href: `/${lang}/${currentBasketItem.linkName}/product/${product.linkName}`, to: `/${lang}/${currentBasketItem.linkName}/product/${product.linkName}` }, { children: product.name.text }), void 0) }, void 0), _jsxs("div", Object.assign({ className: styles["status"] }, { children: [product.status === "COMING_SOON" && (_jsx("span", Object.assign({ className: styles["status-coming-soon"] }, { children: t("comingSoon") }), void 0)), product.status === "IN_PREPARATION" && (_jsx("span", Object.assign({ className: styles["status-in-preparation"] }, { children: t("inPreparation") }), void 0))] }), void 0), _jsx("div", Object.assign({ className: styles.variants }, { children: product.variants.map((variant, variantIndex) => {
                                                            const hasStock = variant.details.stock != null;
                                                            const hasReachedMaxStock = hasStock &&
                                                                variant.details.stock <= variant.quantity;
                                                            return (_jsxs("div", Object.assign({ className: styles["variant"] }, { children: [_jsx("h6", { children: variant.details.name }, void 0), _jsx("h6", { children: `${t("price")}: €${variant.details.priceEur.toFixed(2)}` }, void 0), _jsxs("div", Object.assign({ className: styles["quantity-control"] }, { children: [_jsx("button", Object.assign({ disabled: variant.quantity === 1, className: classNames({
                                                                                    [styles["btn-disabled"]]: variant.quantity === 1,
                                                                                }), onClick: variant.quantity === 1
                                                                                    ? null
                                                                                    : () => handleQuantityChange(product.id, variant.details.id, variant.quantity - 1) }, { children: "-" }), void 0), _jsx("span", { children: variant.quantity }, void 0), _jsx("button", Object.assign({ disabled: hasReachedMaxStock, className: classNames({
                                                                                    [styles["btn-disabled"]]: hasReachedMaxStock,
                                                                                }), onClick: hasReachedMaxStock
                                                                                    ? null
                                                                                    : () => handleQuantityChange(product.id, variant.details.id, variant.quantity + 1) }, { children: "+" }), void 0)] }), void 0), variant.details.isOutOfStock && (_jsx("span", Object.assign({ className: styles["alert"] }, { children: t("outOfStock") }), void 0)), !variant.details.isAvailable && (_jsx("span", Object.assign({ className: styles["alert"] }, { children: t("notAvailable") }), void 0)), _jsx("button", Object.assign({ className: styles["remove-btn"], onClick: () => handleRemove(product.id, variant.details.id) }, { children: t("remove") }), void 0)] }), variant.details.id));
                                                        }) }), void 0)] }), void 0)] }), product.id))) }), void 0), _jsxs("h6", Object.assign({ className: styles["total-title"] }, { children: [t("total"), ": \u20AC", currentBasketItem.products
                                            .reduce((acc, product) => acc +
                                            product.variants.reduce((variantAcc, variant) => variantAcc +
                                                variant.details.priceEur * variant.quantity, 0), 0)
                                            .toFixed(2)] }), void 0), _jsxs("div", Object.assign({ className: styles["total-container"] }, { children: [_jsxs("div", Object.assign({ className: styles["details-container"] }, { children: [_jsxs("div", Object.assign({ className: styles["input-group"] }, { children: [_jsxs("label", { children: [t("selectShippingOption"), " ", _jsx("span", Object.assign({ className: styles["required"] }, { children: "*" }), void 0)] }, void 0), _jsxs("select", Object.assign({ value: selectedOptions[currentBasketItem.merchantId], onChange: (e) => handleOptionChange(currentBasketItem.merchantId, e.target.value) }, { children: [currentBasketItem.shippingOptions.length > 1 ? (_jsx("option", Object.assign({ value: "" }, { children: t("selectShippingOption") }), void 0)) : null, currentBasketItem.shippingOptions.map((shippingOption) => (_jsxs("option", Object.assign({ defaultChecked: currentBasketItem.shippingOptions.length === 1, value: shippingOption.id }, { children: [shippingOption.country, " - ", shippingOption.text, " (\u20AC", shippingOption.priceEur, ")"] }), shippingOption.id)))] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["input-group"] }, { children: [_jsx("label", { children: t("coupon") }, void 0), _jsx("input", { type: "text", value: selectedCoupons[currentBasketItem.merchantId] || "", onChange: (e) => handleCouponChange(currentBasketItem.merchantId, e.target.value) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["input-group"] }, { children: [_jsx("label", { children: t("email") }, void 0), _jsx("input", { type: "email", value: email, onChange: handleEmailChange }, void 0)] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles["button-and-options"] }, { children: [_jsx("button", Object.assign({ disabled: createCheckoutSessionState.loading, onClick: () => onCheckout(currentBasketItem), className: classNames(styles["checkout-btn"], {
                                                        [styles["btn-disabled"]]: createCheckoutSessionState.loading ||
                                                            !selectedOptions[currentBasketItem.merchantId],
                                                    }) }, { children: _jsx("span", { children: t("checkout") }, void 0) }), void 0), _jsx("div", Object.assign({ className: styles["payment-methods"] }, { children: currentBasketItem.paymentMethods.map((method) => {
                                                        switch (method) {
                                                            case PaymentMethod.klarna:
                                                                return _jsx(Klarna, {}, void 0);
                                                            case PaymentMethod.eps:
                                                                return _jsx(Eps, {}, void 0);
                                                            case PaymentMethod.paypal:
                                                                return _jsx(Paypal, {}, void 0);
                                                            case PaymentMethod.card:
                                                                return (_jsx(CreditCard, { style: {
                                                                        height: "32px",
                                                                        width: "32px",
                                                                    } }, void 0));
                                                            case PaymentMethod.sepa_debit:
                                                                return _jsx(Sepa, {}, void 0);
                                                        }
                                                    }) }), void 0)] }), void 0)] }), void 0)] }), void 0)), _jsx(Link, Object.assign({ className: styles["empty-basket"], href: isEmbedded ? `/${lang}/${lastShopLinkName}` : `/${lang}/shops`, to: isEmbedded ? `/${lang}/${lastShopLinkName}` : `/${lang}/shops` }, { children: isEmbedded ? t("goBack") : t("goToShops") }), void 0)] }), void 0)] }), void 0) }, void 0));
};
