var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { ContainerInstance, Service } from "@bluelibs/core";
import { AssetMediaRenderType, AssetMediaTypeFor3D, MerchantsBillingFeature, MerchantsBillingPackageType, } from "@root/api.types";
import { ProjectSmart } from "../smarts/Project.smart";
let AssetService = class AssetService {
    constructor(container) {
        this.container = container;
        this.projectSmart = this.container.get(ProjectSmart);
    }
    getAssetData(asset) {
        const { product, assetPosition, _id } = asset;
        const hasProductPlaced = Boolean(product);
        const media = hasProductPlaced ? product.media : assetPosition.media;
        const mediaType = hasProductPlaced
            ? asset.mediaType
            : asset.assetPosition.mediaType;
        const typeFor3D = hasProductPlaced
            ? asset.typeFor3D
            : asset.assetPosition.typeFor3D;
        const response = {
            type: mediaType,
            id: _id,
        };
        switch (mediaType) {
            case AssetMediaRenderType.IMAGE_2D:
                response.file = media.image2dFiles[0];
                break;
            case AssetMediaRenderType.IMAGE_2D_CAROUSEL:
                response.file = media.image2dFiles;
                break;
            case AssetMediaRenderType.IMAGE_360:
                response.file = media.image360Files[0];
                break;
            case AssetMediaRenderType.IMAGE_360_CAROUSEL:
                response.file = media.image360Files;
                break;
            case AssetMediaRenderType.ITEM_3D:
                if (typeFor3D === AssetMediaTypeFor3D.OBJ_MTL) {
                    response.file = [media.image3dOBJ, media.image3dMTL];
                }
                else if (typeFor3D === AssetMediaTypeFor3D.GLB) {
                    response.file = media.image3DGLB;
                }
                break;
            case AssetMediaRenderType.VIDEO:
                response.file = media.videoUpload;
                break;
        }
        return response;
    }
    showSingle360(asset) {
        const media = asset.product
            ? asset.product.media
            : asset.assetPosition.media;
        this.handle360ImageChange(this.projectSmart.state.isWatching360
            ? ""
            : media.image360Files[0].downloadUrl);
    }
    handleCarousel(asset, type, mediaRenderType) {
        // const entity = document.getElementById(`${asset._id}-entity`);
        const image = document.getElementById(`${asset._id}-image`);
        if (mediaRenderType === AssetMediaRenderType.IMAGE_360_CAROUSEL) {
            if (type === "close") {
                return this.handle360ImageChange("");
            }
        }
        const media = asset.product
            ? asset.product.media
            : asset.assetPosition.media;
        const images = mediaRenderType === AssetMediaRenderType.IMAGE_2D_CAROUSEL
            ? media.image2dFiles
            : media.image360Files;
        let value = 0;
        if (type === "first") {
            value = 0;
        }
        else if (type === "last") {
            value = images.length - 1;
        }
        else {
            const { index } = image.getAttribute("carousel-2d");
            if (type === "refresh") {
                value = index;
            }
            else if (type === "next") {
                value = index + 1 === images.length ? 0 : index + 1;
            }
            else if (type === "prev") {
                value = index === 0 ? images.length - 1 : index - 1;
            }
        }
        image.setAttribute("carousel-2d", `index: ${value}`);
        image.setAttribute("src", `#${asset._id}-${value}`);
        if ([
            AssetMediaRenderType.IMAGE_360,
            AssetMediaRenderType.IMAGE_360_CAROUSEL,
        ].includes(mediaRenderType)) {
            this.handle360ImageChange(images[value].downloadUrl);
        }
    }
    handle360ImageChange(src) {
        const image360sky = document.getElementById("image-360");
        const image360skyWrapper = document.getElementById("image-360-wrapper");
        const camera = document.getElementById("player");
        const cameraPosition = camera.getAttribute("position");
        const image360skyWrapperPosition = this.addNumbersFromStrings(`${cameraPosition.x} ${cameraPosition.y} ${cameraPosition.z}`, "0 -2.5 0");
        image360sky.setAttribute("src", src);
        image360skyWrapper.setAttribute("position", image360skyWrapperPosition);
        this.projectSmart.updateState({ isWatching360: Boolean(src) });
    }
    removeExtensionFromFileName(name) {
        return (name === null || name === void 0 ? void 0 : name.substring(0, name.lastIndexOf("."))) || "-";
    }
    spatialPositionToSpatialPositionForm(position) {
        const answer = {};
        for (const key of ["position", "rotation", "scale"]) {
            const [x, y, z] = position[key].split(" ");
            answer[key] = { x, y, z };
        }
        return answer;
    }
    spatialPositionFormToSpatialPosition(position) {
        const answer = {};
        for (const key of ["position", "rotation", "scale"]) {
            const xyz = position[key];
            answer[key] = `${xyz.x} ${xyz.y} ${xyz.z}`;
        }
        return answer;
    }
    calculatePositionWithDelta(positions, delta) {
        const { position, rotation, scale } = positions;
        const { position: position2, rotation: rotation2, scale: scale2 } = delta;
        return {
            position: this.addNumbersFromStrings(position, position2),
            rotation: this.addNumbersFromStrings(rotation, rotation2),
            scale: this.addNumbersFromStrings(scale, scale2),
        };
    }
    findWorldAssetEntityById(id) {
        return document.getElementById(`${id}-entity`);
    }
    /**
     * @deprecated Render restrictions are handled in the back-end side
     */
    getRenderOptions(billing, numberOfItems) {
        const { isOwnerOfProject } = this.projectSmart.state;
        if (!billing || isOwnerOfProject)
            return {
                shouldRender360: true,
                shouldRender2D: true,
                shouldRender2DCarousel: true,
                shouldRender3D: true,
            };
        const { features, packageType } = billing;
        const { numberOf2DImages, numberOf3DObjects } = numberOfItems;
        const maxRenderable3DItemsCount = (features.includes(MerchantsBillingFeature.ADD_3D_OBJECT) ? 1 : 0) +
            (features.includes(MerchantsBillingFeature.ADD_10_3D_OBJECTS) ? 10 : 0);
        return {
            shouldRender360: features.includes(MerchantsBillingFeature.ADD_360_IMAGE),
            shouldRender2D: packageType === MerchantsBillingPackageType.BRONZE
                ? numberOf2DImages == 0
                : numberOf2DImages < 10,
            shouldRender2DCarousel: true,
            shouldRender3D: numberOf3DObjects < maxRenderable3DItemsCount,
            // video is handled in the BE
        };
    }
    updatePositionsForAsset(asset, positions) {
        for (const key of ["position", "rotation", "scale"]) {
            asset.setAttribute(key, positions[key]);
        }
    }
    addNumbersFromStrings(a, b) {
        const a1 = a.split(" ").map((x) => parseFloat(x));
        const a2 = b.split(" ").map((x) => parseFloat(x));
        return new Array(a1.length)
            .fill(null)
            .map((_, idx) => (a1[idx] || 0) + (a2[idx] || 0))
            .join(" ");
    }
};
AssetService = __decorate([
    Service(),
    __metadata("design:paramtypes", [typeof (_a = typeof ContainerInstance !== "undefined" && ContainerInstance) === "function" ? _a : Object])
], AssetService);
export { AssetService };
