import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Button } from "@bundles/UIAppBundle/components/button";
import { IconArrowLeft } from "@bundles/UIAppBundle/components/svg/icons";
import Dropdown from "@bundles/UIAppBundle/components/dropdown/Dropdown";
import { AcceptedLanguage } from "@root/api.types";
import { I18NService, use, useRouter, useTranslate } from "@bluelibs/x-ui";
import { parse } from "querystring";
const CheckoutHeader = ({ logo, onLanguageChange, onBack }) => {
    const t = useTranslate("components.checkout.header");
    const i18nService = use(I18NService);
    const [activeLanguage, setActiveLanguage] = useState(AcceptedLanguage.en);
    const router = useRouter();
    useEffect(() => {
        let { lang } = parse(router.history.location.search.substring(1));
        if (!lang) {
            lang = AcceptedLanguage.en;
        }
        if (lang) {
            setActiveLanguage(lang);
        }
        i18nService.setLocale(lang);
    }, [router.history.location.search]);
    const dropdownItems = [
        {
            label: "English",
            url: "#",
            onClick: () => {
                setActiveLanguage(AcceptedLanguage.en);
                onLanguageChange(AcceptedLanguage.en);
                i18nService.setLocale(AcceptedLanguage.en);
            },
        },
        {
            label: "Deutsch",
            url: "#",
            onClick: () => {
                setActiveLanguage(AcceptedLanguage.de);
                onLanguageChange(AcceptedLanguage.de);
                i18nService.setLocale(AcceptedLanguage.de);
            },
        },
    ];
    return (_jsxs("div", Object.assign({ className: "cc-checkout-header" }, { children: [_jsxs("div", Object.assign({ className: "cc-checkout-header__back", onClick: onBack }, { children: [_jsx(Button, Object.assign({ bgColor: "dark-gray", color: "white", size: "custom", className: "cc-checkout-header__back-btn" }, { children: _jsx(IconArrowLeft, {}, void 0) }), void 0), _jsx("span", { children: t("back") }, void 0)] }), void 0), _jsx("div", Object.assign({ className: "cc-checkout-header__logo" }, { children: _jsx("img", { src: logo, alt: "" }, void 0) }), void 0), _jsx(Dropdown, { headerButton: _jsxs(Button, Object.assign({ size: "custom", bgColor: "white", color: "gray", className: "cc-lang-switcher__btn" }, { children: [activeLanguage, " ", _jsx(IconArrowLeft, {}, void 0)] }), void 0), items: dropdownItems, position: "bottom" }, void 0)] }), void 0));
};
export default CheckoutHeader;
