export const removeFalsyValues = (obj) => {
    if (!obj) {
        return obj;
    }
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] === null || obj[key] === "") {
            return {
                ...acc,
                [key]: undefined,
            };
        }
        return {
            ...acc,
            [key]: obj[key],
        };
    }, {});
};
