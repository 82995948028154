import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import styles from "./styles.module.scss";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
export const ShopInfoComponent = ({ shopInfo, withoutHr }) => {
    var _a, _b, _c;
    const t = useTranslate("components.shopInfo");
    return (_jsxs(Fragment, { children: [shopInfo && !withoutHr ? (_jsx("div", Object.assign({ className: styles.hr }, { children: _jsx("hr", {}, void 0) }), void 0)) : null, shopInfo &&
                (shopInfo.impressum ||
                    shopInfo.termsOfService ||
                    shopInfo.logo ||
                    shopInfo.description ||
                    shopInfo.phone ||
                    shopInfo.email) && (_jsx("div", Object.assign({ className: styles["shop-settings-wrapper"] }, { children: _jsxs("div", Object.assign({ className: styles["shop-settings"] }, { children: [shopInfo.logo && (_jsx("div", Object.assign({ className: styles.image }, { children: _jsx("img", { src: shopInfo.logo, alt: shopInfo.name }, void 0) }), void 0)), _jsxs("div", Object.assign({ className: styles["shop-info"] }, { children: [_jsx("h4", { children: _jsx("b", { children: shopInfo.name }, void 0) }, void 0), ((_a = shopInfo.impressum) === null || _a === void 0 ? void 0 : _a.text) && (_jsxs("div", Object.assign({ className: styles["shop-settings-details"] }, { children: [_jsx("h5", { children: t("impressum") }, void 0), shopInfo.impressum.text.split("\n").map((text, index) => (_jsxs("span", { children: [text, _jsx("br", {}, void 0)] }, index)))] }), void 0)), ((_b = shopInfo.termsOfService) === null || _b === void 0 ? void 0 : _b.text) && (_jsxs("div", Object.assign({ className: styles["shop-settings-details"] }, { children: [_jsx("h5", { children: t("termsOfService") }, void 0), shopInfo.termsOfService.text
                                            .split("\n")
                                            .map((text, index) => (_jsxs("span", { children: [text, _jsx("br", {}, void 0)] }, index)))] }), void 0))] }), void 0), _jsxs("div", Object.assign({ className: styles["shop-details"] }, { children: [shopInfo.description && (_jsxs("div", Object.assign({ className: styles["shop-settings-details"] }, { children: [_jsxs("h5", { children: [t("about"), " ", _jsx("b", { children: shopInfo.name }, void 0), ":"] }, void 0), (_c = shopInfo.description.text) === null || _c === void 0 ? void 0 : _c.split("\n").map((text, index) => (_jsxs("span", { children: [text, _jsx("br", {}, void 0)] }, index)))] }), void 0)), shopInfo.region && (_jsxs("div", Object.assign({ className: styles["shop-settings-details"] }, { children: [_jsx("h5", { children: t("region") }, void 0), _jsx("h6", { children: shopInfo.region }, void 0)] }), void 0)), (shopInfo.phone || shopInfo.email) && (_jsxs("div", Object.assign({ className: styles["shop-settings-details"] }, { children: [_jsx("h5", { children: t("contact") }, void 0), shopInfo.email && (_jsxs("h6", { children: [t("email"), ":", " ", _jsx("a", Object.assign({ href: `mailto:${shopInfo.email}` }, { children: shopInfo.email }), void 0)] }, void 0)), shopInfo.phone && (_jsxs("h6", { children: [t("phone"), ":", " ", _jsx("a", Object.assign({ href: `tel:${shopInfo.phone}` }, { children: shopInfo.phone }), void 0)] }, void 0))] }), void 0))] }), void 0)] }), void 0) }), void 0))] }, void 0));
};
