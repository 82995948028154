import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
const Button = React.forwardRef(({ component = "button", variant = "filled", size = "normal", color = "white", bgColor = "primary", weight = "bold", disabled, type, className, children, readOnly = false, withShadow, ...other }, ref) => {
    const buttonClasses = classNames("cc-button", [`cc--${size}`], [`cc--${variant}`], [`cc--${color}`], [`cc--bg-${bgColor}`], [`cc--weight-${weight}`], {
        "cc--disabled": disabled,
        ["cc--readonly"]: readOnly,
        ["cc--with-shadow"]: withShadow,
    }, className);
    let Component = component;
    if (Component === "button" && other.href)
        Component = "a";
    const buttonProps = {};
    if (Component === "button") {
        buttonProps.type = type || "button";
        buttonProps.disabled = disabled;
    }
    else {
        buttonProps.role = "button";
    }
    return (_jsx(Component, Object.assign({ className: buttonClasses, ref: ref }, buttonProps, other, { children: _jsx("span", { children: children }, void 0) }), void 0));
});
export default Button;
