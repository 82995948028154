import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { newSmart } from "@bluelibs/smart";
import { SHOP_GET_DISTRICTS } from "@bundles/UIAppBundle/queries/shopGetCountries.query";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { AcceptedLanguage, } from "@root/api.types";
import { useState } from "react";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { ShopsComponent } from "./shops";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { SHOP_GET_ALL_SHOPS } from "@bundles/UIAppBundle/queries/shopGetAllShops.query";
import { ShopSidebarProvider, useShopSidebarContext, } from "./sidebar";
import { ShopSmart } from "@bundles/UIAppBundle/smarts/Shop.smart";
import { ShopLayout } from "@bundles/UIAppBundle/components/shop-layout";
export const ShopsPageWrapper = () => {
    const [_, BasketSmartProvider] = newSmart(BasketSmart);
    const [_2, ShopSmartProvider] = newSmart(ShopSmart);
    return (_jsx(ShopSidebarProvider, { children: _jsx(BasketSmartProvider, { children: _jsx(ShopSmartProvider, { children: _jsx(ShopsPage, {}, void 0) }, void 0) }, void 0) }, void 0));
};
const ShopsPage = () => {
    const [districts, setDistricts] = useState();
    const [shops, setShops] = useState();
    const { filters } = useShopSidebarContext();
    const uiSession = useUISession();
    const lang = uiSession.get("currentLocale", AcceptedLanguage.en);
    const { loading: loadingDistricts } = useQuery(SHOP_GET_DISTRICTS, {
        onCompleted: (data) => {
            setDistricts(data.EndUsersShopGetDistricts);
        },
    });
    const { loading: loadingProducts } = useQuery(SHOP_GET_ALL_SHOPS(lang), {
        variables: {
            input: {
                filters: {
                    district: filters === null || filters === void 0 ? void 0 : filters.bundesland,
                    text: filters === null || filters === void 0 ? void 0 : filters.text,
                },
            },
        },
        skip: !filters,
        onCompleted: (data) => {
            setShops(data.EndUsersShopsGetAll);
        },
        fetchPolicy: "network-only",
    });
    if (!districts || !shops) {
        return (_jsx(ShopLayout, Object.assign({ mainFullHeight: true }, { children: _jsx(Loader, { centerInScreen: true }, void 0) }), void 0));
    }
    return (_jsx(ShopLayout, { children: _jsx(ShopsComponent, { districts: districts, shops: shops }, void 0) }, void 0));
};
