import { jsx as _jsx } from "react/jsx-runtime";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { useAppGuardian } from "@bundles/UIAppBundle/services";
import env from "@root/startup/env";
import { useEffect } from "react";
export const Logout = () => {
    const guardian = useAppGuardian();
    useEffect(() => {
        guardian.logout();
        window.location.href = env.LANDING_APP_URL;
    }, []);
    return _jsx(Loader, {}, void 0);
};
