import { gql } from "@apollo/client";
export const WORLDS_FIND_ONE = gql `
  fragment FileData on AppFile {
    _id

    name
    path
    downloadUrl
  }

  fragment MediaData on Media {
    _id
    name
    videoUrl

    image360Files {
      ...FileData
    }

    image2dFiles {
      ...FileData
    }

    image3dOBJ {
      ...FileData
    }

    image3dMTL {
      ...FileData
    }

    image3DGLB {
      ...FileData
    }

    videoUpload {
      ...FileData
    }
  }

  query ($input: WorldsFindOneVRInput!) {
    WorldsFindOneVR(input: $input) {
      _id

      name {
        text
      }

      description {
        text
      }

      startCoordinates {
        position
        rotation
        scale
      }

      capacity
      assetsCount

      pictureGallery {
        _id
        name

        files {
          ...FileData
        }
      }

      worldFile {
        ...FileData
      }

      meshFile {
        ...FileData
      }

      assetPositions {
        _id

        coordinates {
          position
          rotation
          scale
        }

        mediaType
        typeFor3D

        media {
          ...MediaData
        }
      }
    }
  }
`;
