var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Inject, Service } from "@bluelibs/core";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { REGISTER_END_USER } from "../mutations";
let EndUserService = class EndUserService {
    async register(input) {
        return this.apolloClient.mutate({
            mutation: REGISTER_END_USER,
            variables: {
                input,
            },
        });
    }
};
__decorate([
    Inject(() => ApolloClient),
    __metadata("design:type", typeof (_a = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _a : Object)
], EndUserService.prototype, "apolloClient", void 0);
EndUserService = __decorate([
    Service()
], EndUserService);
export { EndUserService };
