import { gql } from "@apollo/client";
export const SHOP_GET_PRODUCT_CATEGORIES = gql `
  query EndUsersShopGetProductCategories(
    $input: EndUsersShopGetProductCategoriesInput!
  ) {
    EndUsersShopGetProductCategories(input: $input) {
      id
      name

      subcategories {
        id
        name
      }
    }
  }
`;
