import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useSmart } from "@bluelibs/smart";
import SvgIconBasket from "@bundles/UIAppBundle/components/svg/icons/IconBasket";
import styles from "./ShopNavbar.module.scss";
import { Link, useParams } from "react-router-dom";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { I18NService } from "@bluelibs/x-ui-i18n-bundle";
import { use } from "@bluelibs/x-ui-react-bundle";
import { AcceptedLanguage } from "@root/api.types";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import { parse } from "querystring";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import Dropdown from "@bundles/UIAppBundle/components/dropdown/Dropdown";
import { Button } from "@bundles/UIAppBundle/components/button";
import { FacebookRegionalisHeader, IconArrowLeft, IconArrowRight, InstagramRegionalisHeader, } from "@bundles/UIAppBundle/components/svg";
import { Breadcrumbs } from "../breadcrumbs";
import { ShopSmart } from "@bundles/UIAppBundle/smarts/Shop.smart";
import { BasketRoute } from "../../routes";
import classNames from "classnames";
import { links } from "./constants";
const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const uiSession = useUISession();
    const lang = uiSession.get("currentLocale");
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add(styles.noScroll);
        }
        else {
            document.body.classList.remove(styles.noScroll);
        }
    }, [isMenuOpen]);
    return (_jsx("header", Object.assign({ className: styles["header-wrapper"] }, { children: _jsxs("div", Object.assign({ className: classNames(styles.header, {
                [styles["header-opened"]]: isMenuOpen,
            }) }, { children: [_jsx("div", Object.assign({ className: styles.image }, { children: _jsx("a", Object.assign({ href: "https://www.regionalis.biz" }, { children: _jsx("img", { src: "/public/assets/images/regionalis.png", alt: "Logo", className: styles["logo-img"] }, void 0) }), void 0) }), void 0), _jsx("button", Object.assign({ className: styles.menuButton, onClick: toggleMenu }, { children: _jsxs("div", Object.assign({ className: classNames({
                            [styles.isOpen]: isMenuOpen,
                        }) }, { children: [_jsx("div", { className: styles.line }, void 0), _jsx("div", { className: styles.line }, void 0), _jsx("div", { className: styles.line }, void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ className: classNames(styles.items, {
                        [styles.menuOpen]: isMenuOpen,
                    }) }, { children: [links.map((link, index) => (_jsx("div", Object.assign({ className: styles.item }, { children: link.isLink ? (_jsx(Link, Object.assign({ className: classNames({
                                    [styles.active]: window.location.pathname === `/${lang}${link.href}`,
                                }), to: `/${lang}${link.href}`, href: `/${lang}${link.href}` }, { children: link.title }), index)) : (_jsx("a", Object.assign({ href: link.href, target: "_blank" }, { children: link.title }), index)) }), void 0))), _jsxs("div", Object.assign({ className: styles["header-buttons"] }, { children: [_jsx("a", Object.assign({ href: "https://www.facebook.com/regionalis.biz", target: "_blank" }, { children: _jsx(FacebookRegionalisHeader, {}, void 0) }), void 0), _jsx("a", Object.assign({ href: "https://www.instagram.com/regionalis.biz", target: "_blank" }, { children: _jsx(InstagramRegionalisHeader, {}, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export const ShopNavbar = ({ shopName, productName, productSlug, shopLinkName, isDsgvo, isEmbedded, }) => {
    const basketSmart = useSmart(BasketSmart);
    const shopSmart = useSmart(ShopSmart);
    const i18nService = use(I18NService);
    const [activeLanguage, setActiveLanguage] = useState(AcceptedLanguage.en);
    const uiSession = useUISession();
    const router = useRouter();
    const lang = uiSession.get("currentLocale");
    const params = useParams();
    const fallbackLang = AcceptedLanguage.de;
    const navigatorLang = navigator.language.split("-")[0];
    const getQueryParams = () => {
        const url = new URL(currentUrl);
        const query = url.search;
        const queryParams = parse(query.replace("?", ""));
        if (url.pathname === "/shops") {
            queryParams.view = "shops";
        }
        else if (url.pathname === "/shops/products") {
            queryParams.view = "products";
        }
        else if (url.pathname === "/basket") {
            queryParams.view = "basket";
        }
        if (shopLinkName) {
            queryParams.shop = shopLinkName;
        }
        if (productSlug) {
            queryParams.product = productSlug;
        }
        return queryParams;
    };
    const onClick = (language, persist) => {
        setActiveLanguage(language);
        i18nService.setLocale(language);
        if (language !== params.lang) {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const pathname = url.pathname.replace(`/${params.lang}`, "");
            url.pathname = `/${language}${pathname}`;
            router.history.push(url.pathname + url.search);
        }
        if (persist) {
            uiSession.set("currentLocale", language, { persist: true });
        }
    };
    useEffect(() => {
        if (navigator.userAgent.includes("Googlebot")) {
            return;
        }
        if (params.lang && Object.values(AcceptedLanguage).includes(params.lang)) {
            onClick(params.lang, true);
        }
        else if (lang) {
            onClick(lang, true);
        }
        else if (Object.values(AcceptedLanguage).includes(navigatorLang)) {
            onClick(navigatorLang, true);
        }
        else {
            onClick(fallbackLang, true);
        }
    }, []);
    useEffect(() => {
        uiSession.set("embeddedInfo", null, { persist: true });
    }, []);
    useEffect(() => {
        const listener = (event) => {
            if (event.origin !== shopSmart.iframeDomain) {
                return;
            }
            const type = event.data.type;
            if (type === "embedInfo") {
                shopSmart.updateState({
                    isEmbedded: true,
                });
                uiSession.set("embeddedInfo", event.data.embedInfo, { persist: true });
            }
            if (type === "checkout") {
                const { success, merchantId } = event.data;
                let url = BasketRoute.path;
                if (success) {
                    url += `?success=true&merchantId=${merchantId}`;
                }
                else {
                    url += `?cancel=true`;
                }
                router.history.push(url);
            }
        };
        window.addEventListener("message", listener);
        const queryParams = getQueryParams();
        shopSmart.sendMessageToParent({
            type: "ready",
            queryParams,
        });
        return () => {
            window.removeEventListener("message", listener);
        };
    }, []);
    const currentUrl = window.location.href;
    useEffect(() => {
        if (!uiSession.state.embeddedInfo) {
            return;
        }
        const queryParams = getQueryParams();
        shopSmart.sendMessageToParent({
            type: "urlChange",
            queryParams,
        });
    }, [currentUrl]);
    const dropdownItems = [
        {
            label: "English",
            onClick: onClick.bind(null, AcceptedLanguage.en, true),
        },
        {
            label: "Deutsch",
            onClick: onClick.bind(null, AcceptedLanguage.de, true),
        },
    ];
    if (!basketSmart.state.initialised) {
        return null;
    }
    const LanguageSwitcher = (_jsx(Dropdown, { headerButton: ({ isOpen }) => (_jsxs(Button, Object.assign({ size: "custom", bgColor: "custom", color: "gray", className: classNames("cc-lang-switcher__btn", styles["lang-switcher-button"]) }, { children: [activeLanguage, " ", isOpen ? _jsx(IconArrowRight, {}, void 0) : _jsx(IconArrowLeft, {}, void 0)] }), void 0)), items: dropdownItems, position: "bottom" }, void 0));
    const Basket = (_jsxs(Link, Object.assign({ className: styles["icon-basket"], to: "/basket", href: "/basket" }, { children: [_jsx(SvgIconBasket, {}, void 0), _jsx("span", Object.assign({ className: styles["wishlist-count"] }, { children: basketSmart.basket.length }), void 0)] }), void 0));
    return (_jsxs("nav", Object.assign({ className: styles["shop-navbar"] }, { children: [isEmbedded ? null : _jsx(Header, {}, void 0), _jsxs("div", Object.assign({ className: classNames(styles.row, {
                    [styles["row--without-header"]]: isEmbedded,
                }) }, { children: [_jsx(Breadcrumbs, Object.assign({}, { shopName, productName, shopLinkName, isDsgvo, isEmbedded }), void 0), _jsxs("div", Object.assign({ className: styles.rightWrapper }, { children: [_jsx("div", Object.assign({ className: styles.LS }, { children: LanguageSwitcher }), void 0), Basket] }), void 0)] }), void 0)] }), void 0));
};
