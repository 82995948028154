import { ShopPageWrapper } from "./ShopPage";
import { ShopsProductsPageWraper } from "./ShopProductsPage";
import { ShopProductPageWrapper } from "./ShopProductPage";
import { ShopsPageWrapper } from "./ShopsPage";
import { ShopPageDsgvoWrapper } from "./ShopPageDsgvo";
export const SHOP = {
    path: "/shop/:linkName",
    component: ShopPageWrapper,
};
export const SHOP_DIRECT = {
    path: "/:linkName",
    component: ShopPageWrapper,
};
export const SHOP_DSGVO_DIRECT = {
    path: "/:linkName/dsgvo",
    component: ShopPageDsgvoWrapper,
};
export const SHOP_DSGVO = {
    path: "/shop/:linkName/dsgvo",
    component: ShopPageDsgvoWrapper,
};
export const SHOP_PRODUCT = {
    path: "/shop/product/:productId",
    component: ShopProductPageWrapper,
};
export const SHOP_PRODUCT_WITH_SHOP_NAME = {
    path: "/:shopSlug/product/:productSlug",
    component: ShopProductPageWrapper,
};
export const SHOPS_PRODUCTS = {
    path: "/shops/products",
    component: ShopsProductsPageWraper,
};
export const SHOPS = {
    path: "/shops",
    component: ShopsPageWrapper,
};
