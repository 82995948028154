var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
let RouterService = class RouterService {
    build(url, parameters) {
        const [basePath, queryParams] = url.split("?");
        let resultBasePath = basePath + "/";
        let resultQueryParams = queryParams || "";
        Object.entries(parameters).forEach(([k, v]) => {
            resultBasePath = resultBasePath
                .replaceAll(`:${k}/`, `${v}/`)
                .replaceAll(`{{${k}}}`, `${v}`);
            resultQueryParams = resultQueryParams
                .replaceAll(`={${k}}`, `=${v}`)
                .replaceAll(`{{${k}}}`, `${v}`);
        });
        resultBasePath = resultBasePath.slice(0, -1);
        if (queryParams)
            return `${resultBasePath}?${resultQueryParams}`;
        return resultBasePath;
    }
};
RouterService = __decorate([
    Service()
], RouterService);
export { RouterService };
