import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { newSmart, use, useSmart } from "@bluelibs/x-ui";
import { AssetItems, AssetEntities } from "@bundles/UIAppBundle/components";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { NotFound } from "@bundles/UIAppBundle/overrides";
import { WORLDS_FIND_ONE } from "@bundles/UIAppBundle/queries/worldsFindOne.query";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import { useState } from "react";
import "../Project/aframe-components/index";
import { AssetService } from "@bundles/UIAppBundle/services";
import { renderToStaticMarkup } from "react-dom/server";
import { EMPTY_STRING_FOR_STATIC_MARKUP_RENDER } from "../Project/constants";
import { WithVRHelmet } from "@bundles/UIAppBundle/components/vr-helmet";
export const WorldWrapper = ({ id }) => {
    const [_, ProjectSmartProvider] = newSmart(ProjectSmart);
    return (_jsx(ProjectSmartProvider, { children: _jsx(World, { id: id }, void 0) }, void 0));
};
export const World = ({ id }) => {
    var _a, _b;
    const [world, setWorld] = useState();
    const assetService = use(AssetService);
    const projectSmart = useSmart(ProjectSmart);
    const { loading, error } = useQuery(WORLDS_FIND_ONE, {
        onCompleted: (response) => setWorld(response.WorldsFindOneVR),
        variables: {
            input: {
                worldId: id,
            },
        },
        skip: !id,
    });
    if (loading || (world === undefined && !error))
        return (_jsx(WithVRHelmet, { children: _jsx(Loader, { centerInScreen: true }, void 0) }, void 0));
    if (!world)
        return (_jsx(WithVRHelmet, { children: _jsx(NotFound, { context: "world-not-found" }, void 0) }, void 0));
    const project = {
        world,
        assetInfos: world.assetPositions.map((assetPosition) => ({
            assetPosition,
        })),
    };
    const { startCoordinates } = project.world;
    const isMobile = AFRAME.utils.device.isMobile();
    const isVR = AFRAME.utils.device.checkHeadsetConnected() && !isMobile;
    const isDesktop = !isMobile && !isVR;
    const controls = ["gamepad", "keyboard", "checkpoint"];
    const hasNipples = !isDesktop;
    if (hasNipples) {
        controls.push("nipple");
    }
    const controlsString = controls.join(",");
    const avatarUrl = (_b = (_a = projectSmart.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl;
    const [startPositionX, startPositionY, startPositionZ] = startCoordinates.position.split(" ");
    const text = renderToStaticMarkup(_jsxs("a-scene", { children: [_jsx(AssetItems, Object.assign({}, { project, avatarUrl }), void 0), _jsxs("a-entity", Object.assign({ id: "rig", "movement-controls": `constrainToNavMesh: true; speed: 0.35; controls: ${controlsString};` }, (hasNipples
                ? { "nipple-controls": "mode: static; bottomMargin:120px;" }
                : {}), { rotation: startCoordinates.rotation, position: startCoordinates.position }, { children: [_jsx("a-entity", Object.assign({ id: "player", camera: EMPTY_STRING_FOR_STATIC_MARKUP_RENDER, position: "0 1.6 0", "look-controls": "pointerLockEnabled: true", visible: "false" }, { children: _jsx("a-cursor", {}, void 0) }), void 0), _jsx("a-entity", { "laser-controls": "hand : left;", raycaster: "objects: .clickable; far: 5" }, void 0), _jsx("a-entity", { "laser-controls": "hand : right;", raycaster: "objects: .clickable; far: 5" }, void 0)] }), void 0), _jsx(AssetEntities, Object.assign({}, { project, isForWorld: true, assetService, projectSmart }), void 0)] }, void 0));
    const processedText = text.replaceAll(`="${EMPTY_STRING_FOR_STATIC_MARKUP_RENDER}"`, "");
    return (_jsx(WithVRHelmet, { children: _jsx("div", { dangerouslySetInnerHTML: {
                __html: processedText,
            } }, void 0) }, void 0));
};
