import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
const Loader = ({ size = "medium", className, centerInScreen, }) => {
    const loaderClasses = classNames("cc-loader", [`cc--${size}`], className, {
        "cc-center-in-screen": centerInScreen,
    });
    return _jsx("div", { className: loaderClasses }, void 0);
};
export default Loader;
