import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { newSmart, use, useEventManager, useRouter, useSmart, useTranslate, useUIComponents, useUISession, } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { CartSidebar, ProjectActionBar, ProjectInteractions, ProjectLogin, ProjectObjects, } from "@bundles/UIAppBundle/components";
import { AssetEntities } from "@bundles/UIAppBundle/components/AssetEntities";
import { AssetItems } from "@bundles/UIAppBundle/components/AssetItems";
import { AssetClickedEvent } from "@bundles/UIAppBundle/events/AssetClicked.event";
import { ANALYTICS_ENTER_PROJECT } from "@bundles/UIAppBundle/mutations";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { AcceptedLanguage } from "@root/api.types";
import { useForm } from "antd/lib/form/Form";
import React, { Fragment, useEffect, useState } from "react";
import { components } from "./aframe-components";
import "./Project.scss";
import { ChatSidebar } from "@bundles/UIAppBundle/components/chat/Chat";
import { ChatSmart } from "@bundles/UIAppBundle/smarts/Chat.smart";
import { parse } from "querystring";
import "./aframe-components/index";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { NotFound } from "@bundles/UIAppBundle/overrides";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import { WishlistSmart } from "@bundles/UIAppBundle/smarts/Wishlist.smart";
import { toast } from "react-toastify";
import { ProjectMobileFullscreen } from "./ProjectMobileFullscreen";
import { renderToStaticMarkup } from "react-dom/server";
import { AssetService } from "@bundles/UIAppBundle/services";
import { EMPTY_STRING_FOR_STATIC_MARKUP_RENDER } from "./constants";
import { WithVRHelmet } from "@bundles/UIAppBundle/components/vr-helmet";
export const ProjectPageWrapper = (props) => {
    const [_, OpenViduSmartProvider] = newSmart(ChatSmart);
    const [_2, ProjectSmartProvider] = newSmart(ProjectSmart);
    const [_3, WishlistSmartProvider] = newSmart(WishlistSmart);
    const isMobile = window.AFRAME ? AFRAME.utils.device.isMobile() : false;
    return (_jsx(ProjectSmartProvider, { children: _jsx(WishlistSmartProvider, { children: _jsxs(OpenViduSmartProvider, { children: [_jsx(ProjectPage, Object.assign({}, props), void 0), isMobile && _jsx(ProjectMobileFullscreen, {}, void 0)] }, void 0) }, void 0) }, void 0));
};
export const ProjectPage = ({ linkName }) => {
    var _a, _b;
    const t = useTranslate("pages.project");
    const UIComponents = useUIComponents();
    const eventManager = useEventManager();
    const guardian = useAppGuardian();
    const projectSmart = useSmart(ProjectSmart);
    const wishlistSmart = useSmart(WishlistSmart);
    const uiSession = useUISession();
    const state = projectSmart.state;
    const router = useRouter();
    const project = projectSmart.project;
    // Don't try to fetch the project if you're embedded but not logged in, yet
    // It means the login token didn't arrive in VR (so to speak)
    useEffect(() => {
        if (state.isEmbedded && !guardian.state.isLoggedIn)
            return;
        projectSmart.updateState({ linkName });
        projectSmart.enterProject();
    }, [guardian.state.isLoggedIn]);
    useEffect(() => {
        if (projectSmart.state.project) {
            wishlistSmart.updateWishlist();
        }
    }, [projectSmart.state.project]);
    useEffect(() => {
        if (!project)
            return;
        uiSession.set("projectId", project._id, { persist: true });
        uiSession.set("merchantId", project.merchantId, { persist: true });
        uiSession.set("projectLinkName", linkName, { persist: true });
    }, [project]);
    const onLeave = () => {
        router.go(Routes.Wishlist);
    };
    const onInquiry = () => {
        //
        router.go(Routes.Wishlist);
    };
    const onLogout = async () => {
        try {
            await projectSmart.onLogout();
            await wishlistSmart.onLogout();
            toast.info(t("successLogout"));
        }
        catch (err) {
            toast.error(err.toString());
        }
    };
    // initialise language from query param (if possible)
    useEffect(() => {
        const { lang } = parse(router.history.location.search.substring(1));
        if (lang) {
            uiSession.set("currentLocale", lang, {
                persist: true,
            });
        }
        else {
            uiSession.set("currentLocale", AcceptedLanguage.en, {
                persist: true,
            });
        }
    }, []);
    // setup user state
    useEffect(() => {
        projectSmart.onLogin();
    }, [guardian.state.isLoggedIn, project]);
    // setup listeners & eventManager
    useEffect(() => {
        eventManager.addListener(AssetClickedEvent, projectSmart.onAssetClicked);
        projectSmart.sendMessageToParent({
            type: "pre-initialise",
        });
        window.addEventListener("message", projectSmart.messageEventListener);
        return () => {
            eventManager.removeListener(AssetClickedEvent, projectSmart.onAssetClicked);
            window.removeEventListener("message", projectSmart.messageEventListener);
        };
    }, [projectSmart.state.isEmbedded]);
    // register components
    useEffect(() => {
        try {
            for (const [componentName, component] of Object.entries(components)) {
                AFRAME.registerComponent(componentName, component);
            }
        }
        catch {
            // they have already been registered
        }
    }, []);
    const error = state.errorProject;
    if (error) {
        const code = (_b = (_a = error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.code;
        switch (code) {
            case "PROJECT_NOT_LIVE":
                return (_jsxs(Fragment, { children: [_jsx(NotFound, { withLogin: true, context: "project-not-live" }, void 0), _jsx("div", Object.assign({ style: { display: "none" } }, { children: _jsx(ProjectLogin, { onFinishLogin: projectSmart.enterProject }, void 0) }), void 0)] }, void 0));
            case "PROJECT_DOES_NOT_EXIST":
                return _jsx(NotFound, { context: "project-not-found" }, void 0);
        }
        return _jsx(UIComponents.Error, { error: error.toString() }, void 0);
    }
    if (state.isLoadingProject || state.project === undefined) {
        return _jsx(Loader, { centerInScreen: true }, void 0);
    }
    if (!project)
        return _jsx(UIComponents.NotAuthorized, {}, void 0);
    return (_jsx(AFrameVRComponent, Object.assign({}, {
        onLogout,
        onLeave,
        onInquiry,
    }), void 0));
};
export const AFrameVRComponent = (props) => {
    var _a, _b, _c;
    const { onLogout, onLeave, onInquiry } = props;
    const projectSmart = useSmart(ProjectSmart);
    const assetService = use(AssetService);
    const state = projectSmart.state;
    const { project } = state;
    const { merchantDefaultVideoCoordinates, startCoordinates } = project.world;
    const projectId = project._id;
    const basketSmart = useSmart(WishlistSmart);
    useEffect(() => {
        basketSmart.setConfig({
            projectId,
        });
    }, []);
    const { state: { wishlist: basket }, addToWishlist: addToBasket, removeFromWishlist: removeFromBasket, } = useSmart(WishlistSmart);
    const guardian = useAppGuardian();
    const [form] = useForm();
    const [loaded, setLoaded] = useState(false);
    const [isObjectsOpen, setIsObjectsOpen] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [projectLoginVisible, setProjectLoginVisible] = useState(false);
    const onCloseProjectLogin = () => setProjectLoginVisible(false);
    const onLogin = () => setProjectLoginVisible(true);
    const eventManager = useEventManager();
    const [enterProjectAnalytics] = useMutation(ANALYTICS_ENTER_PROJECT);
    const NAF = window.NAF;
    useEffect(() => {
        NAF.schemas.getComponentsOriginal = NAF.schemas.getComponents;
        NAF.schemas.getComponents = (template) => {
            if (!NAF.schemas.hasTemplate("#avatar-template")) {
                NAF.schemas.add({
                    template: "#avatar-template",
                    components: [
                        // position and rotation are added by default if we don't include a template, but since
                        // we also want to sync the color, we need to specify a custom template; if we didn't
                        // include position and rotation in this custom template, they'd not be synced.
                        "position",
                        "rotation",
                        // this is how we sync a particular property of a particular component for a particular
                        // child element of template instances.
                        {
                            selector: ".head",
                            component: "material",
                            property: "color", // property is optional; if excluded, syncs everything in the component schema
                        },
                    ],
                });
            }
            const components = NAF.schemas.getComponentsOriginal(template);
            return components;
        };
    }, []);
    const onCameraChange = () => {
        const player = document.getElementById("player");
        player.removeAttribute("networked");
        const adapter = window.NAF.connection.adapter;
        if (adapter) {
            adapter.enableCamera(state.hasCamera);
        }
        player.setAttribute("networked", `template:#avatar-template${state.hasCamera
            ? "-camera"
            : state.isFromMerchantsHeader
                ? "-non-existent"
                : ""};attachTemplateToLocal:false;`);
    };
    const setupAssetsListeners = () => {
        const assets = document.querySelectorAll("[id*=-entity]");
        const listeners = [];
        for (const asset of assets) {
            const id = asset.getAttribute("id");
            const projectAssetInfoId = id.substring(0, id.indexOf("-"));
            const onClick = () => {
                eventManager.emit(new AssetClickedEvent({
                    projectAssetInfoId,
                }));
            };
            listeners.push(onClick);
            asset.addEventListener("click", onClick);
            // asset.addEventListener("raycaster-intersected", onClick);
        }
        return [assets, listeners];
    };
    useEffect(() => {
        var _a;
        const easyrtc = (_a = window.NAF.connection.adapter) === null || _a === void 0 ? void 0 : _a.easyrtc;
        if (!easyrtc)
            return;
        if (easyrtc._presetMediaConstraints)
            return;
        easyrtc._presetMediaConstraints = {
            audio: {
                sampleSize: 8,
                echoCancellation: true,
            },
        };
    }, [project, loaded]);
    const onMicrophoneChange = () => {
        const adapter = window.NAF.connection.adapter;
        if (adapter) {
            adapter.enableMicrophone(state.hasMicrophone);
        }
    };
    useEffect(() => {
        const onConnect = () => {
            onCameraChange();
            onMicrophoneChange();
        };
        document.body.addEventListener("connected", onConnect);
        return () => {
            document.body.removeEventListener("connected", onConnect);
        };
    }, []);
    useEffect(() => {
        if (!loaded)
            return;
        const [assets, listeners] = setupAssetsListeners();
        return () => {
            for (const [index, asset] of assets.entries()) {
                asset.removeEventListener("click", listeners[index]);
            }
        };
    }, [loaded, project === null || project === void 0 ? void 0 : project.assetInfos]);
    useEffect(() => {
        if (state.isOwnerOfProject === false) {
            enterProjectAnalytics({
                variables: {
                    input: {
                        projectId,
                    },
                },
            });
        }
    }, [state.isOwnerOfProject]);
    useEffect(() => {
        const scene = document.querySelector("a-scene");
        window.onNetworkedSceneConnect = () => {
            if (state.isEmbedded === undefined)
                return;
            projectSmart.sendMessageToParent({
                type: "initialised",
            });
        };
        const onLoad = () => {
            if (state.isFromMerchantsHeader) {
            }
            else {
            }
            setLoaded(true);
            scene.emit("connect");
        };
        if (scene.hasLoaded) {
            onLoad();
        }
        else {
            scene.addEventListener("loaded", onLoad);
        }
    }, []);
    useEffect(() => {
        onCameraChange();
    }, [state.hasCamera]);
    useEffect(() => {
        onMicrophoneChange();
    }, [state.hasMicrophone]);
    const isMobile = AFRAME.utils.device.isMobile();
    const isVR = AFRAME.utils.device.checkHeadsetConnected() && !isMobile;
    const isDesktop = !isMobile && !isVR;
    const controls = ["gamepad", "keyboard", "checkpoint"];
    const hasNipples = !isDesktop;
    if (hasNipples) {
        controls.push("nipple");
    }
    const controlsString = controls.join(",");
    /**
     * Submit log as info
     */
    useEffect(() => {
        projectSmart.submitLog({ isMobile, isVR });
    }, []);
    const canUseAudio = projectSmart.canUseAudio();
    const canUseVideo = projectSmart.canUseVideo();
    const avatarUrl = (_c = (_b = (_a = projectSmart.avatar) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadUrl) !== null && _c !== void 0 ? _c : "";
    const componentText = renderToStaticMarkup(_jsxs("a-scene", Object.assign({ id: "scene", "networked-scene": `
          room: ${project._id};
          debug: true;
          adapter: easyrtc;
          audio: ${canUseAudio}; 
          video: ${canUseVideo};
          connectOnLoad: false;
          onConnect: onNetworkedSceneConnect;
` }, { children: [_jsx(AssetItems, Object.assign({}, { project, avatarUrl }), void 0), _jsx("a-entity", Object.assign({ id: "image-360-wrapper" }, { children: _jsx("a-sky", { visible: state.isWatching360, id: "image-360", radius: "10", src: "" }, void 0) }), void 0), _jsxs("a-entity", Object.assign({ id: "rig", networked: "template:#rig-template;", "movement-controls": `constrainToNavMesh: true; speed: 0.35; controls: ${controlsString};`, position: state.isFromMerchantsHeader
                    ? merchantDefaultVideoCoordinates.position
                    : startCoordinates.position, rotation: state.isFromMerchantsHeader
                    ? merchantDefaultVideoCoordinates.rotation
                    : startCoordinates.rotation }, (hasNipples
                ? { "nipple-controls": "mode: static; bottomMargin:120px;" }
                : {}), { children: [_jsx("a-entity", Object.assign({ id: "player", networked: "template:#avatar-template;", position: "0 1.6 0", camera: EMPTY_STRING_FOR_STATIC_MARKUP_RENDER, visible: "false", "look-controls": "pointerLockEnabled: true" }, { children: _jsx("a-cursor", {}, void 0) }), void 0), _jsx("a-entity", { "laser-controls": "hand : left;", raycaster: "objects: .clickable; far: 5" }, void 0), _jsx("a-entity", { "laser-controls": "hand : right;", raycaster: "objects: .clickable; far: 5" }, void 0)] }), void 0), _jsx(AssetEntities, Object.assign({}, { project, projectSmart, assetService }), void 0)] }), void 0));
    const processedText = componentText.replaceAll(`="${EMPTY_STRING_FOR_STATIC_MARKUP_RENDER}"`, "");
    return (_jsxs(WithVRHelmet, { children: [_jsx("div", { dangerouslySetInnerHTML: { __html: processedText } }, void 0), state.isOwnerOfProject !== undefined && (_jsx(ProjectInteractions, Object.assign({ isObjectsOpen: isObjectsOpen, setIsObjectsOpen: setIsObjectsOpen }, { basket, addToBasket, removeFromBasket }), void 0)), _jsx(ProjectObjects, { isMobile: isMobile, isOpen: isObjectsOpen, setIsOpen: setIsObjectsOpen, form: form, projectId: projectId }, void 0), !state.isEmbedded && state.isOwnerOfProject !== undefined && (_jsxs(Fragment, { children: [!guardian.state.isLoggedIn && (_jsx(ChatSidebar, { isOpen: state.isChatting, onClose: projectSmart.switchIsChatting }, void 0)), projectLoginVisible && (_jsx(ProjectLogin, { onClose: onCloseProjectLogin }, void 0)), !state.isOwnerOfProject && (_jsx(CartSidebar, Object.assign({ onInquiry: onInquiry, isOpen: isCartOpen, setIsOpen: setIsCartOpen, items: basket.map(({ product: prod, projectId }) => {
                            var _a, _b;
                            return ({
                                name: prod === null || prod === void 0 ? void 0 : prod.name.text,
                                image: (_b = (_a = prod === null || prod === void 0 ? void 0 : prod.media.image2dFiles) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.downloadUrl,
                                id: prod === null || prod === void 0 ? void 0 : prod._id,
                                projectId,
                            });
                        }) }, {
                        removeFromBasket,
                    }), void 0)), _jsx(ProjectActionBar, Object.assign({}, {
                        onLogin,
                        onLogout,
                        onInquiry,
                        onLeave,
                    }), void 0)] }, void 0))] }, void 0));
};
