import { gql } from "@apollo/client";
export const PROJECTS_FIND = gql `
  {
    ProjectsFind {
      _id

      name

      state
      type

      linkName

      isApprovedByAdmin
    }
  }
`;
